/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import topImg from "../assets/img/blog/front.png";
import blue from "../assets/img/blog/DevOp.jpg";
import { Link } from "react-router-dom";

function Devops() {
  return (
    <div className='blog-page'>
      {/*Left Section*/}
      <div className='left-section'>
        <ul className='page-list'>
          {Array.from({ length: 5 }, (_, i) => (
            <li key={i}>Page {i + 1}</li>
          ))}
        </ul>
      </div>
      {/*MAin Section*/}
      <div className='middle-section'>
        <img className='img' src={topImg} alt='laptop on coffe table' />
        <h1>DevOps</h1>
        <p className='topic'>
          DevOps is the combination of two terms development and operations
          meant to present a collective or shared perspective to perform tasks
          through a company's application development and IT. Around 2007, the
          software development and IT operations groups voiced concerns about
          the conventional software development approach, in which developers
          created code separately from operations, which distributed and
          supported the code. This led to the birth of the DevOps movement.
          Combining the terms "development" and "operations," DevOps describes
          the practice of fusing different fields into a single, continuous
          activity.<br></br>
          The primary purpose of DevOps is to provide a sustainable
          infrastructure for specific applications while ensuring high
          scalability. Scalable apps, after all, are extremely dependable,
          automated, and essential in today's software development process.
        </p>
        <div className='d-flex'>
          <p className='line '></p>
          <p className='date'>Jan 01 , 2023</p>
        </div>
        <h3>Four vital principles of DevOps:</h3>
        <p className='data-st'>
          <li> Automation of the software development life cycle</li>
          <li> Collaboration and communication</li>
          <li> Continuous improvement and minimization of waste</li>
          <li> Hyper focus on user needs with short feedback loops.</li>
          Software development lifecycle automation: SDLC eliminates manual
          processes, allowing environments to be set up more quickly.
          Additionally, it increases software productivity and speeds up the
          production of high-quality goods. The following are some of the
          primary advantages of SDLC automation.
          <li> Rapid change</li>
          <li> Removing manual processes</li>
          <li> Minimum time requirement</li>
          <li> Automated evaluation </li>
          <li> Improved efficiency</li>
        </p>
        <h3>Collaboration and communication:</h3>
        <p>
          A good DevOps team must have automation but to be a notch Devops team
          there must be collaboration and communication. This is the main idea
          of bringing dev and ops together. This seems to be a very
          straightforward DevOps philosophy but as with most things, the devil
          is in the details. While ops professionals concentrate on various
          things like tools, compliance, and the cloud, developers want to
          produce a code and get it out to the world, while the security team
          wants to make sure the code is secure. Development, operation, and
          security do not have the same priorities, may not communicate using
          the same language, and are likely to address issues from quite
          different angles.
        </p>
        <h3>Continuous improvement and minimization of waste:</h3>
        <p>
          Using lean and agile methodologies DevOps focuses on reducing waste
          and continuous improvement whether by automating repetitive tasks like
          testing or by decreasing the number of steps required to complete
          tasks.to improve performance, well-functioning DevOps teams
          continuously measure performance metrics, including the
          mean-time-to-recovery, number of bugs found, and release times.
        </p>
        <h3>Hyper focus on user needs with short feedback loops:</h3>

        <p>
          The final must-know DevOps principle is the importance of involving
          the end user in all stages of the process. The DevOps team can take a
          step back and focus on what real users want and how to give it to them
          through automation, improved communication, collaboration, and
          continuous improvement. There is no doubt that getting into the user's
          head is difficult and teams can struggle to put processes in place to
          accomplish this. Another aspect of this is that once user feedback is
          gathered, it must be delivered quickly so that time is not wasted.
          That is why short feedback loops are critical and the team must work
          hard to make them even shorter as time passes.
        </p>
        <h3>DevOps and its application life cycle:</h3>
        <p>
          DevOps affect the application life cycle, during its complete life
          cycle few steps are mandatory like planning, development, delivery,
          and operative stages. As each stage depends on other stages. All
          phases are involved in the DevOps culture process. <br></br>
          The trip planned by UX is made possible by UI, which ranges from
          hitting a button and scrolling down a page to swiping across an image
          gallery.<br></br>
          The DevOps development phase is where all of the fundamental software
          development activity takes place. It accepts plans for the current
          iteration as input, which is typically in the form of task
          assignments. It then generates software artifacts that represent the
          modified functionality. Development necessitates not only code-writing
          tools like Visual Studio, but also support services like version
          control, issue management, and automated testing.
        </p>
        <img className='img' src={blue} alt='Devops background in blue color' />
        <h3>Goals and benefits of DevOps:</h3>
        <h4>Faster time to market:</h4>
        <p>
          With high efficiencies and the best team collaboration, self-operating
          tools, and regular deployment – DevOps teams can decrease the time
          from product inauguration to market launch.
        </p>
        <h3>Market competitive:</h3>
        <p>
          The very first aim of the DevOps team is a customer–first focus. By
          various methods like marrying agility, team management, and focus on
          the customer experience, which DevOps team can increase the
          competition in the marketplace.
        </p>
        <h3>Maintain system stability and reliability:</h3>
        <p>
          Teams can boost the stability and reliability of the goods and
          services they deploy by implementing continuous improvement processes.
          These methods help in the reduction of failures and risks.
        </p>
        <h3>Disaster recovery plans:</h3>
        <p>
          A disaster recovery plan is a written collection of policies and
          processes designed to safeguard an organization's IT assets in the
          case of a disaster.  <br></br>
          The plan often includes scenarios, run books, backups, and
          instructions for getting the business and IT services up and running.
           <br></br>
          This is especially important in situations involving system failure,
          downtime, security breaches, or data loss
        </p>
        <h3>Enhance the mean-time recovery:</h3>
        <p>
          The average recovery time, the time it takes to recover from a failure
          or breach is indicated by this metric. Teams should measure and work
          to improve this metric to manage software failures, security breaches,
          and continuous improvement plans.
        </p>
        <h3>DevOps and its tools:</h3>
        <p>
          DevOps tools are applications, which are used to automate the software
          deployment process. It primarily focuses on communication and
          collaboration among product management, software development, and
          operations personnel.<br></br>
          DevOps solutions also enable teams to automate most, software
          development activities, such as build, dispute resolution, dependency
          management, and deployment, minimizing the need for manual labor.
        </p>
        <h3>DevOps and its automation tools:</h3>
        <ul>
          <li> Jenkins </li>
          <li> Docker</li>
          <li> Puppet</li>
          <li> Apache maven</li>
          <li> Gradle</li>
        </ul>
        <h3>CI/CD DevOps tool:</h3>
        <ul>
          <li> Circle CI </li>
          <li> Bamboo</li>
          <li> Team City</li>
          <li> Travis CI</li>
          <li> Buddy</li>
        </ul>
        <h3>DevOps and its control version tools:</h3>
        <ul>
          <li> Git</li>
          <li> GitLab</li>
        </ul>
        <h3>DevOps and its configuration management tools:</h3>
        <ul>
          <li> Chef </li>
          <li> Kubernetes</li>
          <li> Ansible</li>
          <li> Vagrant</li>
          <li> Consul</li>
          <li> Terraform</li>
        </ul>
        <h3>DevOps tools for testing</h3>
        <ul>
          <li> Selenium </li>
          <li> Tricentis Tosca</li>
          <li> Test sigma</li>
          <li> Soap UI</li>
        </ul>
        <h3>DevOps tools for monitoring:</h3>
        <ul>
          <li> Nagios </li>
          <li> Prometheus</li>
          <li> New Relic</li>
          <li> Pager duty</li>
          <li> Splunk</li>
        </ul>
        <h3>DevSecOps</h3>
        <p>
          Devsecops is a branch of DevOps that continuously automates and
          integrates security across the whole DevOps life cycle, from planning
          feedback and back to planning.<br></br>Another way to say it is that
          DevSecOps is what DevOps was always meant to be. <br></br>
          However, two of the initial big (and seemed insurmountable) obstacles
          of DevOps adoption were integrating security knowledge into
          cross-functional teams (a culture issue) and integrating security
          automation into the DevOps lifecycle (a technical issue).<br></br>
          Security became known as the "Team of 'No,'" and was viewed as an
          expensive bottleneck in many DevOps approaches.<br></br>
          DevSecOps arose as a distinct endeavor to integrate and automate
          security as planned. <br></br>Security is a "first-class" citizen and
          stakeholder in DevSecOps, alongside development and operations, and it
          integrates security into the development process with a product
          emphasis
        </p>
        <h3>Top skills of DevOps</h3>
        <p>
          The role of a DevOps engineer does not have a clear career path.
          Professionals from a range of backgrounds can apply for the post. A
          software developer for example can learn operations skills such as the
          configuration of the hosting infrastructure to become a DevOps
          engineer. A system administrator with coding scripting and test
          knowledge can become a DevOps engineer.
        </p>
        <ul>
          <li> infrastructure developer </li>
          <li> site reliability engineer</li>
          <li> build and release engineer</li>
          <li> full-stack developer</li>
          <li> automation specialist</li>
          <li> CI/CD platform engineer</li>
        </ul>
        <p>
          DevOps provides a solution at a time when every technology leader is
          faced with enabling security, reliability, agility, dealing with
          security breaches, improving time to market, and undergoing huge
          technological transformations. An inherent tension can exist between
          Development and Operations, resulting in delayed time to market for
          new products and features, poor quality, higher outages and technical
          debt, reduced engineering productivity, and increased staff
          unhappiness and burnout. . DevOps ideas and patterns enable teams to
          resolve this fundamental, ongoing conflict. DevOps may necessitate new
          cultural and management norms, as well as changes in technical
          practices and architecture. As a result, developer productivity is
          maximized, organizational learning is enhanced, employee satisfaction
          is high, and the ability to compete in the marketplace is enhanced.
        </p>
        <p>
          DevOps is a business imperative as well as a technological imperative.
          DevOps is applicable and relevant to all organizations that need to
          increase the flow of planned work through the technology organization
          while maintaining customer quality, reliability, and security
        </p>
        <Link to='/blog' className='text-center'>
          <button Linkto='./Blogs.jsx' className='btn-learn-more'>
            <i className='fa fa-arrow-left' />
            Go Back
          </button>
        </Link>
      </div>
      {/* Right Section */}
      <div className=' right-section'>
        <div className='search-bar'>
          <input type='text' placeholder='Search' />
          <button className='bnt'>Search</button>
        </div>
        <h2>
          <strong> Recent Post</strong>
        </h2>
        <ul className='blog-list'>
          <li>
            <a href='/devops'> The Ultimate Guide To DevOps </a>
          </li>
          <li>
            <a href='/devscop'>DevOps Consultancy</a>
          </li>
          <li>
            <a href='#'>Deployment</a>
          </li>
          <li>
            <a href='/Uxui'>The Definitive Guide to UXUI</a>
          </li>
          <li>
            <a href='#'>
              The Definitive to <br></br> React and React Native{" "}
            </a>
          </li>
          <li>
            <a href='#'>
              The Definitive To <br></br>Cloud-based Services
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Devops;
