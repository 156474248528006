import React from "react";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <div id='error404'>
      <h1>
        "Unlock the full potential of this page and experience a world of
        knowledge and entertainment, waiting to be explored and enjoyed!"
      </h1>
      <Link to='/blog'>
        <button Linkto='./Blogs.jsx' className='but'>
          <i className='fa fa-arrow-left' />
        </button>
      </Link>
    </div>
  );
}

export default ErrorPage;
