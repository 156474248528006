/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";
import image1 from "../assets/img/Contact.png";
import emailjs from "@emailjs/browser";
import "../components/ContactForm.css";

function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emailStatus, setEmailStatus] = useState(null); // Added state to track email status

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_knev3lk",
        "template_bheat1f",
        form.current,
        "zlE79AehLCUdlDl5X"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmailStatus("success");

          // Reset form fields after successful submission
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");

          // Clear the status message after a few seconds
          clearStatusMessage();
        },
        (error) => {
          console.log(error.text);
          setEmailStatus("failed");
        }
      );
  };
  // Function to clear the status message after a certain time
  const clearStatusMessage = () => {
    setTimeout(() => {
      setEmailStatus(null);
    }, 15000); // Hide after 15 seconds (adjust the timing as needed)
  };

  return (
    <section id='contact' className='contact'>
      <div className='container'>
        <div className='section-title' data-aos='zoom-out'>
          <h2>Contact</h2>
          <p style={{ marginTop: "40px" }}>Contact Us</p>
        </div>
        <div>
          <h5>we are here for you! How can we help?</h5>
        </div>
        <div className='form'>
          <div className='contact-form'>
            <section>
              <form className='contact' ref={form}>
                <div className='cls'>
                  <input
                    type='text'
                    placeholder='Enter Your Full-Name...'
                    id='name'
                    name='user_name'
                    required
                  />
                </div>
                <div className='cls'>
                  <input
                    type='email'
                    placeholder='Enter Valid Email Address'
                    id='email'
                    name='user_email'
                    required
                  />
                </div>
                <div className='cls'>
                  <input
                    type='Subject'
                    placeholder='Subject'
                    id='subject'
                    name='subject'
                    required
                  />
                </div>
                <div className='cls'>
                  <textarea
                    placeholder='Your Message here...'
                    id='message'
                    name='user_message'
                    required
                    defaultValue={""}
                  />
                </div>
                <button className='button' type='submit' onClick={handleSubmit}>
                  Submit
                </button>
              </form>
              {/* Display the email status message */}
              {emailStatus && (
                <div className={`status-message ${emailStatus}`}>
                  {emailStatus === "success"
                    ? "Email sent successfully!"
                    : "Sorry, email not sent. Please try again later."}
                </div>
              )}
            </section>
          </div>
          <div className='col' data-aos='fade-left'>
            <img src={image1} alt='' className='img-fulid' />
            <div className='info'>
              <div className='address text-center'>
                <i className='bi bi-geo-alt' />
                <p>
                  2ND FLOOR BAKHTAWAR-SHAH PLAZA,ZULFIQARABAD JUITAL , GILGIT
                  15100
                </p>
              </div>
              <div className='email text-center'>
                <i className='bi bi-envelope' />
                <p>contact@cloudlem.com</p>
              </div>
              <div className='phone text-center'>
                <i className='bi bi-phone' />
                <p>0355-4502615</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
