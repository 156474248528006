/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import "./Team.css";
import Contact from "../../Contact";
import Socila from "./Social";
import img from "./Zaheer.jpeg";

function Section({ id, children }) {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        entry.target.classList.add("visible");
      }
    });
    observer.observe(ref.current);

    return () => observer.unobserve(ref.current);
  }, []);

  return (
    <section id={id} ref={ref}>
      {children}
    </section>
  );
}

function Zaheer() {
  return (
    <div className='app text-center m-5'>
      <Section className='img'>
        <img src={img} alt='Mr-Domini' style={{ width: "20%" }} />

        <h1>Zaheer IqbaL</h1>
        <h2>Full-Stack Web Developer</h2>
      </Section>

      <Section id='about'>
        <h1>About Me</h1>
        <h2>
          I am developer with experience in building websites for small and
          <br></br>
          medium-sized businesses. Whether you're trying to win work, list your
          <br></br>
          services or even create a whole online store-I am a well organized
          <br></br>
          helping hand.
        </h2>
      </Section>

      <Section id='skills'>
        <h1>My Skills</h1>
        <h2>
          I'm a full-time freelancer loaded with profound knowledge of<br></br>
          ReactJS, NextJS, WordPress, HTML, CSS, jQuery , SEO and SQL.I was
          <br></br>
          working with local clients and have enough experience to work{" "}
          <br></br>
          worldwide.
        </h2>
      </Section>

      <Section id='projects'>
        <h1>My Projects</h1>
        <h2>
          I have experience in other live fields. Knowledgeable with Civil
          <br></br>
          Aviation Authority standards and policies. Proficient in maintaining
          <br></br>
          and repairing FTD parts. Excellent knowledge of aircraft technology
          <br></br>
          and mechanic trade. Excellent Knowledge of computers Both software and
          <br></br>
          hardware.
        </h2>
      </Section>

      <Section id='social'>
        <h2>Social Connection Bridges</h2>
        <Socila />
      </Section>
      <Section id='contact'>
        <Contact />
      </Section>
    </div>
  );
}
export default Zaheer;
