import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./testimonial.css";

const testimonials = [
  {
    name: "",
    role: "",
    text: "Over the years, DevOps Prodigy has consistently resolved potential emergencies for the agency. The team leveraged their technical knowledge and skills to execute well. Once they receive the requirements, they're able to work near independently.",
  },
  {
    name: "",
    role: "",
    text: "The engagement met the expectations of the internal team. DevOpsProdigy provides thorough and reliable reporting to ensure a smooth workflow. The team is hard-working, professional, and driven.",
  },
  {
    name: "",
    role: "",
    text: " I've worked with Cloudlem's Engineer Rehmat on two separate projects and have been really pleased with the results each times! I originally recruited him to assist with what I thought were easy web development concerns, but both turned out to be much more difficult jobs that much surpassed my area of competence.",
  },
  // Add more testimonials here
];

const customDotColors = ["#ff6347", "#32cd32", "#1e90ff", "#ffa500"]; // Add more colors as needed

const CustomDot = ({ index, onClick, isActive }) => {
  return (
    <span
      className={`custom-dot ${isActive ? "active" : ""}`}
      style={{ backgroundColor: customDotColors[index] }}
      onClick={onClick}
    />
  );
};

const TestimonialSlider = () => {
  return (
    <div className='testimonial-slider'>
      <div className='section-title'>
        <h2 className=' mt-10 text-2xl font-bold'>Testimonials</h2>
        <p className=' mt-3 text-5xl font-bold'>Testimonials</p>
      </div>
      <p className='text-center mt-6 text-2xl '>A Word From Our Customer</p>
      <p className='text-center mt-3 text-2xl '>
        we've been helping businesses since 2020.
      </p>
      <Carousel
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        stopOnHover={true}
        renderIndicator={(onClickHandler, isSelected, index) => (
          <CustomDot
            onClick={onClickHandler}
            isActive={isSelected}
            index={index}
          />
        )}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className='testimonials mt-20'>
            <p className='text-lg mt-20 testimonial-text'>{testimonial.text}</p>
            <p className='text-sm mt-20 font-bold'>{testimonial.name}</p>
            <p className='text-sm mt-20'>{testimonial.role}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TestimonialSlider;
