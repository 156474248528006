import React from "react";
import ScrollToTop from "./scrolltotop";
// import { Link } from "react-router-dom";
import Devops from "../assets/img/blog/Devops.a1cf45330e8ab54e6d56.jpg";
import Consultancy from "../assets/img/blog/Consultancy.7cfb410322ed6c289f34.jpg";
import development from "../assets/img/blog/development.727e14272d3bfc2dd8c7.jpg";
import uxui from "../assets/img/blog/uxui.3d1440103d5db3e66637.jpg";

function Blog() {
  return (
    <section id='blog' className='blog'>
      <ScrollToTop />
      <div className='container'>
        <div className='section-title' data-aos='zoom-out'>
          <h2>Blogs</h2>
          <p style={{ marginTop: "40px" }}>Get Latest About The Company</p>
        </div>
        <div className='flex gap-2'>
          <div className=' col-lg-3 col-md-6'>
            <img src={Devops} alt='' style={{ borderRadius: "20px" }} />
            <h6
              style={{
                textAlign: "right",
                opacity: "0.5",
                marginTop: "10px",
                fontSize: "10px",
              }}>
              DATE 21/05/2022
            </h6>
            <h3>The ultimate guide to Devops</h3>
          </div>
          <div className='col-lg-3 col-md-6 mt-4 mt-md-0'>
            <img src={Consultancy} alt='' style={{ borderRadius: "20px" }} />
            <h6
              style={{
                textAlign: "right",
                opacity: "0.5",
                fontSize: "10px",
                marginTop: "10px",
              }}>
              DATE 17/08/2022
            </h6>
            <h3>Devops Consultancy</h3>

            <p />
          </div>
          <div className='col-lg-3 col-md-6 mt-4 mt-lg-0'>
            <img src={development} alt='' style={{ borderRadius: "20px" }} />
            <h6
              style={{
                textAlign: "right",
                fontSize: "10px",
                opacity: "0.5",
                marginTop: "10px",
              }}>
              DATE 01/01/2023
            </h6>
            <h3>Deployment</h3>
          </div>
          <div
            className='col-lg-3 col-md-6 mt-4 mt-lg-0 '
            id='bodydesc'
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              // height: "736px",
            }}>
            <img src={uxui} alt='' style={{ borderRadius: "20px" }} />
            <h6
              style={{
                textAlign: "right",
                opacity: "0.5",
                marginTop: "10px",
                fontSize: "10px",
              }}>
              DATE 05/11/2022
            </h6>
            <h3>The definitive guide to UXUI</h3>
          </div>
        </div>
        <div className=' d-flex  justify-content-center'>
          {" "}
          <a href='/blog' className='btn-learn-more' target='_top'>
            Learn More
          </a>
        </div>
      </div>
    </section>
  );
}

export default Blog;
