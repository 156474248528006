/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
// import Logo from "../logo512.png";
import "./Navbar/navbar.css";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const goto = () => {
    navigate("/");
    // alert("Go back");
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header
      id='navbar'
      className=' navbar fixed-top d-flex align-items-center header-transparent  '>
      <div className='container d-flex align-items-center '>
        <Link to='/' className='  d-flex logo-box'></Link>
        <div
          className={`menu-icon ${isOpen ? "open" : ""}`}
          onClick={handleMenuClick}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={`navbar-menu ${isOpen ? "open" : ""}`}>
          <li onClick={goto}>
            <Link
              to='/'
              className='nav-link scrollto'
              href='#'
              onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to='Aboutus'
              className='nav-link '
              href='Aboutus'
              onClick={closeMenu}>
              About Us
            </Link>
          </li>
          <li>
            <Link to='Services' className='nav-link ' onClick={closeMenu}>
              Services
            </Link>
          </li>

          <li>
            <Link
              to='Error404'
              className='nav-link scrollto'
              // href='#testimonial'
              onClick={closeMenu}>
              Testimonial
            </Link>
          </li>
          <Link to='blog' className='nav-link ' onClick={closeMenu}>
            Blog
          </Link>
          <li>
            <Link
              to='Contact'
              className='nav-link scrollto'
              // href='#contact'
              onClick={closeMenu}>
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
