/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import uxui from "../assets/img/blog/deploy.png";
import Setp from "../assets/img/blog/deployment.png";
import { Link } from "react-router-dom";

function Devops() {
  return (
    <div className='blog-page'>
      {/*Left Section*/}
      <div className='left-section'>
        <ul className='page-list'>
          {Array.from({ length: 5 }, (_, i) => (
            <li key={i}>Page {i + 1}</li>
          ))}
        </ul>
      </div>
      {/*MAin Section*/}
      <div className='middle-section'>
        <img className='img' src={uxui} alt='Devops background in blue color' />
        <h1>Deployment</h1>
        <p className='topic'>
          In software and web development, deployment refers to the process of
          moving changes or updates from one deployment environment to another. 
          <br></br>
          When building a website, you will always have a live website, also
          known as the live environment or production environment. You can add
          additional environments if you want to make changes without affecting
          your live website. <br></br>These are known as development
          environments or deployment environments. A local environment, a
          development environment, and a staging environment are typical
          additional development environments (also known as staging sites). The
          number of environments you require is entirely up to you and is
          determined by the complexity of the project you are working on. 
          <br></br>
          When working with multiple deployment environments, the most common
          deployment model is the classic "left to right" deployment model.
          <br></br>Changes are made in the local, development, or staging
          environments (depending on the setup) and pushed from left to right
          through the various environments until they reach the live
          environment.<br></br>The new changes will be visible in the live
          environment once this deployment process is completed.<br></br>The
          image above depicts a very simplified and traditional method of
          handling deployments when working with websites in a CMS.<br></br>You
          do not have to use all of the above environments, but the process
          remains the same.
        </p>
        <img src={Setp} alt='Devops background in blue color' />
        <p>
          The image above depicts a very simplified and traditional method of
          handling deployments when working with websites in a CMS. <br></br>You
          do not have to use all of the above environments, but the process
          remains the same.Using multiple environments provides several
          benefits, the most important of which is the ability to make changes
          without affecting your live website. The deployment process takes care
          of the rest once the changes have been made, tested, and are ready to
          be pushed live.
        </p>
        <h3>What steps are included in the deployment process flow?</h3>
        <p>
          Planning, development, testing, deploying, and monitoring are the five
          steps in the deployment process flow.
        </p>
        <h4>1. Always have a software deployment strategy in place.</h4>
        <p>
          It is best to have a deployment plan that you follow - every time - to
          ensure that the deployment process goes as smoothly as possible.  A
          plan ensures that everything is done the same way each time changes
          are made.This is especially useful when multiple users are working on
          the same project at the same time. A deployment plan should include
          rules for when to deploy from local environments to development or
          staging sites, as well as timelines for when new changes can be
          implemented in a live environment.By having a set plan, you reduce the
          risk of conflicting changes and ensure that the deployment process is
          as smooth and easy as possible.If you are working on an open-source
          project, you can also do Release Candidates and have your community
          test it for any bugs you might have missed.Together with a general
          strategy, it is critical to prepare for every change you intend to
          make.  Little modifications will be made quickly, while major changes
          will require a much longer procedure. <br></br>You are significantly
          more likely to have a seamless deployment procedure if you plan.
        </p>
        <h4>2. The development itself</h4>
        <p>
          When you have a plan in place, it is time to start building.  Working
          only on local or development environments is crucial to ensuring that
          any development may be done simultaneously and without causing any
          problems.  The time has come to begin testing and deploying the
          changes using your environment setup when the development process is
          complete. Uncertain about working locally versus in a development
          environment?  Then look at the section on deployment best practices.
        </p>
        <h4>3. Verify your modifications</h4>
        <p>
          To ensure that no defects enter the final production environment,
          testing your modifications is essential.  Nevertheless, testing cannot
          be finished until your changes have been applied to fresh
          environments.<br></br>It is time to deploy the modifications to the
          following environment after you have verified that all of your changes
          function properly in your local or development environment.  This
          should continue up to your staging environment, where the last round
          of QA testing should be conducted.  It is time to deploy it live if
          everything has been thoroughly tested and functions in a setting
          similar to your live environment.<br></br>It is critical to have a
          plan in place for dealing with bugs that arise in any environment. 
          Typically, any change that fails testing on the staging environment
          should be returned to the development phase and, once fixed, should
          work its way through the environments again.
        </p>
        <h4>4. Making changes live in the environment</h4>
        <p>
          Once all of the tests on previous environments have been completed and
          any bugs have been resolved, it is time to deploy your changes to the
          live environment.  This should be a safe move, but anyone who has
          worked with software development knows that anything can go wrong
           Therefore, even though it is easy to stop here, it is important to
          include the final step of the process: monitoring.
        </p>
        <h4>5. Keep track of your progress.</h4>
        <p>
          Once your new changes are live and real users are actively using your
          website or application, it is critical to ensure that everything is
          functioning properly. Regardless of how well you plan, users may
          encounter problems or perform actions that you did not anticipate
          during the planning and development process.<br></br>A good monitoring
          tip is to schedule releases for times when the least number of users
          will notice and when you have development resources available in case
          something needs to be fixed.  As a result, the number of users
          affected by any bug will be kept to a minimum, and you will have
          people ready to fix it or roll back the changes if necessary. Manual
          deployment, automated deployment, and continuous deployment are all
          methods of deployment. <br></br>Manual deployment is time-consuming
          and error-prone because it involves manual steps.  In contrast,
          automated deployment automates the deployment process, lowering the
          risk of errors and shortening the deployment time.  Continuous
          deployment takes deployment automation to the next level by delivering
          updates to the production environment as soon as they are ready.
        </p>
        <h4>Best practices for deployment</h4>
        <p>
          As previously stated, when working with deployment environments, it is
          critical to have a plan and a clear process in place within your
          team.  To supplement that process, we have gathered some best
          practices that you can incorporate into your own. Please keep in mind
          that the following best practices primarily concern software and web
          development.  Other types of development may necessitate additional
          considerations in your deployment workflow.
        </p>
        <h4> Use Git</h4>
        <p>
          This may seem obvious, but a version control system is essential to
          any good deployment workflow.  If you work in a team without it, you
          are more likely to make mistakes. Even if you are the only developer
          on a project, using Git is highly recommended in case you need to go
          back to previous versions or if someone new joins your team. Without
          Git, it will be difficult to ensure consistency in your deployment
          workflow, which may result in more errors due to deploying unfinished
          code or not having all team members work on the same version of the
          code
        </p>
        <h4>Work in branches</h4>
        <p>
          As a general rule of thumb, your team should be working in branches.
          By doing this, it will be feasible to work on several items
          concurrently without having any negative effects on one another. An
          illustration would be the discovery of a bug that needs to be
          rectified.  A developer can easily create a new branch of the
          development environment if they are working on a bug while utilizing
          the same branch to work on a new feature.  In this manner, two
          distinct branches will not conflict or maybe lead to future merger
          disputes. While deploying to a staging environment, your Q&A Team
          benefits from working with branches as well.  Testers will have a
          clearer understanding of what was pushed and what they should test if
          your changes are separated into distinct branches and merged.
        </p>
        <h4>
          What are the advantages of deployment and multiple environments?
        </h4>
        <p>
          Less chance of damaging a live website Reducing the possibility of
          modifications having a detrimental effect on a live website is one of
          the primary justifications for employing multiple environments and
          depending on deployment.  Larger changes can be made in separate
          environments without running the danger of breaking anything in the
          live environment, while smaller changes can simply be made straight on
          a live website. It also ensures that nobody runs the danger of causing
          damage because of another user's changes when numerous users are
          working on the same page.
        </p>
        <h4> Save time</h4>
        <p>
          You can make changes in whichever sequence you like without having to
          worry about damaging something on a live website. This implies that
          you can streamline your process for making the modifications without
          giving the website's appearance or functionality any thought. Working
          locally also has the benefit of allowing updates to be processed more
          quickly and relieving you of any need for network problems. By pushing
          all of your modifications at once rather than having to do so in
          several smaller steps, you will also save time when it comes to
          deploying your change
        </p>
        <h4>Time-sensitive content is easier to manage</h4>
        <p>
          If you are running time-sensitive campaigns that can only go live on a
          specific day or time, running multiple environments and using
          deployment can save you a lot of stress. You can finish your campaign
          without worrying about it being visible to your users if you create
          all of the content on a staging (or similar) environment. When it
          comes time to launch, all of this can be made visible in a matter of
          minutes by deploying it to your live environment. In addition, if the
          deployment tool includes user roles with permission settings, a
          content editor can do all of this, including deploying the changes,
          without involving a developer.
        </p>
        <Link to='/blog' className='text-center'>
          <button linkto='./Blogs.jsx' className='btn-learn-more'>
            <i className='fa fa-arrow-left' />
            Go Back
          </button>
        </Link>
      </div>

      {/* Right Section */}
      <div className=' right-section'>
        <div className='search-bar'>
          <input type='text' placeholder='Search' />
          <button className='bnt'>Search</button>
        </div>
        <h2>
          <strong> Recent Post</strong>
        </h2>
        <ul className='blog-list'>
          <li>
            <a href='/devops'> The Ultimate Guide To Devops </a>
          </li>
          <li>
            <a href='/devscop'>Devops Consultancy</a>
          </li>
          <li>
            <a href='#'>Deployment</a>
          </li>
          <li>
            <a href='/Uxui'>The Definitive Guide to UXUI</a>
          </li>
          <li>
            <a href='#'>
              The Definitive to <br></br> React and React Native{" "}
            </a>
          </li>
          <li>
            <a href='#'>
              The Definitive To <br></br>Cloud-based Services
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Devops;
