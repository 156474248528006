/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { FaArrowCircleUp } from "react-icons/fa";
// import { Button } from "../Styles";
import "./cbtn.css";
import styled, { keyframes } from "styled-components";
import AddIcCallRoundedIcon from "@mui/icons-material/AddIcCallRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import ContactsRoundedIcon from "@mui/icons-material/ContactsRounded";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const comeIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const FloatingContainer = styled.div`
  position: fixed;
  width: 100px;
  height: 100px;
  bottom: 5%;
  right: 0;
  margin: 35px 25px;

  &:hover {
    height: 350px;

    .floating-button {
      box-shadow: 0 10px 25px rgba(44, 179, 240, 0.6);
      transform: translateY(5px);
      transition: all 0.3s;
    }

    .element-container .float-element:nth-child(1) {
      animation: ${comeIn} 0.4s forwards 0.2s;
    }

    .element-container .float-element:nth-child(2) {
      animation: ${comeIn} 0.4s forwards 0.4s;
    }

    .element-container .float-element:nth-child(3) {
      animation: ${comeIn} 0.4s forwards 0.6s;
    }

    .element-container .float-element:nth-child(4) {
      animation: ${comeIn} 0.4s forwards 0.6s;
    }
  }

  .floating-button {
    position: absolute;
    width: 65px;
    height: 65px;
    background: #ef603e;
    bottom: 0;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    line-height: 65px;
    text-align: center;
    font-size: 23px;
    z-index: 100;
    box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
    cursor: pointer;
    transition: all 0.3s;
  }

  .float-element {
    position: relative;
    display: block;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 15px auto;
    color: white;
    font-weight: 500;
    text-align: center;
    line-height: 50px;
    z-index: 0;
    opacity: 0;
    transform: translateY(100px);
  }

  .float-element .material-icons {
    vertical-align: middle;
    font-size: 16px;
  }

  .float-element:nth-child(1) {
    background: rgba(239, 96, 62, 1);
    box-shadow: 0 20px 20px -10px rgba(239, 96, 62, 0.5);
  }

  .float-element:nth-child(2) {
    background: #4caf50;
    box-shadow: 0 20px 20px -10px rgba(76, 175, 80, 0.5);
  }

  .float-element:nth-child(3) {
    background: #ff9800;
    box-shadow: 0 20px 20px -10px rgba(255, 152, 0, 0.5);
  }
  .float-element:nth-child(4) {
    background: #ff9800;
    box-shadow: 0 20px 20px -10px rgba(255, 152, 0, 0.5);
  }
`;

function Contactusbutn() {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  window.addEventListener("scroll", toggleVisible);

  return (
    <div
      className='contact-button'
      style={{ display: visible ? "inline" : "none" }}>
      {/*Flooting buttons*/}

      <FloatingContainer className='floating-container'>
        <div className='floating-button'>
          <ContactsRoundedIcon />
        </div>
        <div className='element-container'>
          <Link to='Contact'>
            <a href='' linkto={"../pages/Contact.jsx"} target='_top'>
              {" "}
              <span className='float-element tooltip-left'>
                <AddIcCallRoundedIcon />
              </span>
              <span className='float-element'>
                <EmailRoundedIcon />
              </span>
              <span className='float-element'>
                <SendRoundedIcon />
              </span>
            </a>
          </Link>
          <span className='float-element tooltip-left' onClick={scrollToTop}>
            <ArrowUpwardIcon />
          </span>
        </div>
      </FloatingContainer>
    </div>
  );
}

export default Contactusbutn;
