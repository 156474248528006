import React from "react";
import imglo from "./images/Northstar.png";
import NorthStar from "./images/Northstar.png";
import Primus from "./images/primus.png";
import Myplug from "./images/myplug.png";

const Companies = () => {
  return (
    <div className='Trusted'>
      <div className='Name'>
        <h3>Trusted By Top Companies</h3>
      </div>
      <div className='copm-logs'>
        <img href='#' className='imexg' src={NorthStar} alt='Imglo' />
        <img href='#' className='comp-logo' src={Primus} alt='Imglo' />
        <img href='#' className='comp-logo' src={Myplug} alt='Imglo' />
        <img href='#' className='imexg' src={imglo} alt='Imglo' />
      </div>
    </div>
  );
};

export default Companies;
