import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section id='about' className='about'>
      <div className='container '>
        <div className='section-title'>
          <h2>About</h2>
          <p>Who we are</p>
        </div>
        <div className='flex content'>
          <div className='col-lg-6 st'>
            <ul>
              <li>
                Cloudlem is an emerging DevOps consulting company with a wealth
                of experience in supporting customers globally to implement
                DevOps practices.
              </li>
              <li>
                {" "}
                Cloudlem's expertise includes cloud migration, maintenance, and
                supporting customers in building scalable and highly available
                architecture on well-known cloud service providers such as AWS,
                Azure, GCP, and more
              </li>
              <li>
                The company's services include deployment, automation,
                infrastructure, dockerization, security recovery planning, and
                execution to benefit its customers.
              </li>
              <li>
                Cloudlem offers 24/7 managed services with a 10-minute SLA,
                providing customers with timely and efficient support.
              </li>
            </ul>
          </div>
          <div class='col-lg-6 pt-4 pt-lg-0'>
            <ul>
              <li>
                Cloudlem has a team of talented and experienced engineers who
                are passionate about helping clients succeed in the
                ever-evolving world of technology.
              </li>
              <li>
                The company helps various organizations transform by utilizing
                data, analytics, and the cloud.
              </li>
              <li>
                Cloudlem’s goal is to help clients automate their manual
                processes using DevOps tools and techniques to exceed their
                expectations.
              </li>
              <li>
                In addition, Cloudlem offers a cloud infrastructure consulting
                service to help clients manage their applications.
              </li>
            </ul>
          </div>
        </div>
        <div className='text-center'>
          <Link to={"/Aboutus"}>
            <span className='btn-learn-more'>Learn More</span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default About;
