/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Logo from "../logo512.png";
import { Link } from "react-router-dom";

const footer = () => {
  return (
    <footer id='footer' className='footer'>
      <div className='container'>
        <div className='Upper-section'>
          <div className='footer-col'>
            <img src={Logo} alt='logo' style={{ width: "200px" }} />
          </div>
          <div className='footer-col'>
            <h4>Cloudlem</h4>
            <ul>
              <li className=' d-flex flex-column'>
                <a href='Aboutus'>About us </a>
                <a href='Error404'>Testimonials</a>
                <a href='contact'>Contact us</a>
                <Link to='/blog'>
                  {" "}
                  <a Linkto='../pages/Blogs.jsx' href='/blog'>
                    Blogs
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          <div className='footer-col'>
            <h4>Resource</h4>
            <ul>
              <li className='row'>
                <a href='#faq'>FAQ'S</a>
                <a href='#faq'>Help Center</a>
                <a href='#portfolio'>Portfolio</a>
                <Link to='/careers' target='_top'>
                  Career
                </Link>
              </li>
            </ul>
          </div>
          <div className='footer-col'>
            <h4>Follow us</h4>
            <div className='social-links '>
              <li>
                <a
                  href='https://www.linkedin.com/company/cloudlem'
                  target='_blank'
                  rel='noreferrer'>
                  {" "}
                  <i className='bi bi-linkedin' />
                </a>
                <a
                  href='https://twitter.com/CloudlemOffical'
                  target='_blank'
                  rel='noreferrer'>
                  {" "}
                  <i className='bi bi-twitter' />
                </a>
                <a
                  href='https://instagram.com/cloudlemoffical?igshid=YmMyMTA2M2Y='
                  target='_blank'
                  rel='noreferrer'>
                  {" "}
                  <i className='bi bi-instagram' />
                </a>
                <a
                  href='https://www.facebook.com/Cloudlemoffical'
                  target='_blank'
                  rel='noreferrer'>
                  <i className='bi bi-facebook' />{" "}
                </a>
              </li>
            </div>
          </div>
          <div className='footer-col'>
            <h4>
              Join Our Commuinity
              <i className='bi bi-fire ' />
            </h4>
            <div className=' join'>
              <input id='email' placeholder='Enter your email' type='email' />
              <button className='eco'>
                <i className='bi bi-arrow-right' />
              </button>
            </div>
          </div>
        </div>
        <div>
          <img
            className='divider'
            src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABGAAAAABCAYAAACR6dhFAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAfSURBVHgB7cAxAQAACAIw7Ub/SpqCb9skNwAAAADUPIGKAc0UZGYPAAAAAElFTkSuQmCC'
            alt='divider'
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              color: "white",
            }}
          />
        </div>
        <div className='row ' style={{ marginTop: "17px" }}>
          <div className='copyright'>
            © Copyright{" "}
            <strong>
              <span>CLOUDLEM</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className='credits'>
            Designed by <Link to='contact'>Development@CLOUDLEM.COM</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default footer;
