import React from "react";
import "./CareerPage.css";
import backgroundImage from "../assets/img/blog/Rectangle805.png";

function CareerPage() {
  return (
    <div className='CareerPage '>
      <div
        className='header row '
        style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className='text'>
        <h1>Join Our Team</h1>
        <p>
          We welcome you to explore the employment opportunities at CLOUDLEM if
          you are a passionate and talented DevOps professional or wanting to be
          one. We are continuously on the hunt for bright individuals that share
          our goal of using DevOps to change software development.
        </p>
        <button className='join-btn'> Join Our Team</button>
      </div>
      <div className='context  '>
        <div className='contont'>
          <h2>BENEFITS</h2>{" "}
          <ul>
            <h4>Why You Should Join Our Team </h4>
            <p>
              As a part of our team, you will have the chance to work on a wide
              range of innovative projects for customers in a variety of
              sectors. You will contribute to the creation of solutions that
              will influence the future of software delivery, from start-ups to
              enterprise-level organization.
            </p>
          </ul>
        </div>

        <div className='content'>
          <div className='d-flex'>
            <div>
              <i class='fa-solid fa-people-group'></i>
              <h2>Team Work</h2>
              <p>
                CLOUDLEM understands that genuine DevOps success requires great
                cooperation and collaboration. When you join our organization,
                you become a member of a bright and varied team that
                collaborates to provide excellent outcomes for our clients. We
                solve complicated issues with new solutions by collaborating,
                sharing expertise, and using each others talents. Our
                collaborative environment promotes open communication and a
                culture of lifelong learning.{" "}
              </p>
            </div>
            <div>
              <i class='fa-solid fa-clock-rotate-left'></i>
              <h2>Secured Future</h2>
              <p>
                When deciding on a professional path, it's critical to consider
                long-term stability and progress. CLOUDLEM is dedicated to
                providing our team members with a secure future as well as
                several chances for professional growth. Join us, and we'll lay
                the groundwork for your success in the ever-changing world of
                DevOps.You'll get access to the most up-to-date tools,
                technologies, and industry best practices to keep your skills
                sharp and keep you ahead of the competition.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className='content'>
          <div className='d-flex'>
            <div>
              <i class='fa-solid fa-graduation-cap'></i>
              <h2>Learning Opportunity</h2>
              <p>
                CLOUDLEM understands that constant learning is essential for
                personal and professional development. As a part of our team,
                you will have access to a variety of learning activities aimed
                to help you improve your abilities, broaden your knowledge, and
                advance your DevOps career. We are devoted to investing in your
                growth and offering the resources you require to flourish in the
                ever-changing world of DevOps.We provide comprehensive training
                programs that cover a wide range of DevOps topics, such as
                tools, processes, and best practices.{" "}
              </p>
            </div>
            <div>
              <i class='fa-regular fa-circle-up'></i>
              <h2>Upgrade Skills</h2>
              <p>
                CLOUDLEM believes in the potential of lifelong learning and
                professional growth. We recognize that the world of DevOps is
                constantly growing and that in order to stay ahead, it is
                critical to improve your skills and broaden your knowledge base.
                That is why we provide a variety of training and skill
                improvement programmes designed to equip you with the most
                up-to-date technologies, methodologies, and best practises in
                the DevOps business. Our training programs cover
                various subjects, from fundamental DevOps concepts to
                sophisticated automation and cloud technologies.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='joinus' style={{ marginTop: "5%" }}>
        <h4>Come Join Us</h4>
        <h2>Career Openings</h2>
        <p>
          we are looking for creative, talented self-starters to join the
          cloudlem family <br></br>
          cheack out our open roles below and fill out an application.
        </p>
      </div>

      <div className='jobs '>
        <div className=' exprience'>
          <h3>DEVOPS</h3> <h3>WEB DEVELOPMENT</h3> <h3>Digital Markeeting</h3>{" "}
          <h3>DESIGN</h3>{" "}
        </div>
        <div className=' exprience'>
          {" "}
          <h3>Aws Engineer</h3> <h3>React Developer</h3>
          <h3>SEO</h3>
          <h3>App Developer</h3>{" "}
        </div>
        <div className=' exprience'>
          {" "}
          <div>
            <h6>Exprience</h6> <h4>1 Year</h4>
          </div>
          <div>
            <h6>Exprience</h6> <h4>1 Year</h4>
          </div>
          <div>
            <h6>Exprience</h6> <h4>1 Year</h4>
          </div>
          <div>
            <h6>Exprience</h6> <h4>1 Year</h4>
          </div>
        </div>
        <div className=' exprience'>
          <div>
            <h6>Dead-Line</h6>
            <h4>Nil</h4>
          </div>
          <div>
            <h6>Dead-Line</h6>
            <h4>Nil</h4>
          </div>
          <div>
            <h6>Dead-Line</h6>
            <h4>Nil</h4>
          </div>
          <div>
            <h6>Dead-Line</h6>
            <h4>Nil</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerPage;
