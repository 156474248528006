import "./assets/css/style.css";
import Hero from "./components/Hero";
import About from "./components/About";
import Feature from "./components/Feature";
import Service from "./components/Service";
import Technology from "./components/Technology";
import Testimonial from "./components/Testimonial";
import Blog from "./components/Blog";
import Faq from "./components/Faq";
import ContactForm from "./components/contact";

function Home() {
  return (
    <div className='App'>
      <Hero />

      <About />
      <Feature />
      <Service />
      <Technology />
      <Testimonial />
      <Blog />
      <Faq />
      <ContactForm />
    </div>
  );
}

export default Home;
