import React, { useState, useEffect } from "react";
import "./assets/css/style.css";
import { Routes, Route } from "react-router-dom";

// Components
import Header from "./components/header";
import Home from "./Home";
import Footer from "./components/footer";
import ScrollToTop from "./components/scrolltotop";
import Cbtn from "./components/Contactusbutn";
// import Chatbot from "./chat/chat";
// Pages
import Blog from "./pages/Blogs";
import Contact from "./pages/Contact";
import Uxui from "./pages/Uxui";
import Devops from "./pages/Devops";
import Deploy from "./pages/Deployment";
import DevopsCons from "./pages/DevopsCons";
import Error404 from "./pages/404";
import Error from "./pages/ErrorPage";
import Careers from "./pages/Careers";
import Aboutus from "./pages/Aboutus/Aboutus";
import Services from "./pages/Services";
import ZaheerIqbal from "./pages/TEAM/ZaheerIqbal/ZaheerIqbal";
import Whatsapp from "./pages/TEAM/ZaheerIqbal/Whatsapp";
import Preloader from "./pre";

function App() {
  const [load, upadateLoad] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/data"); // Replace with your API endpoint
        const jsonData = await response.json();
        setData(jsonData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        upadateLoad(false);
      }
    };

    const timer = setTimeout(fetchData, 1200);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className='App' id={load ? "no-scroll" : "scroll"}>
      <Preloader load={load} />
      <Header />
      <ScrollToTop />
      <Cbtn />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Blog' element={<Blog />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Uxui' element={<Uxui />} />
        <Route path='/Devops' element={<Devops />} />
        <Route path='/Deploy' element={<Deploy />} />
        <Route path='/DevopsCons' element={<DevopsCons />} />
        <Route path='/Error404' element={<Error404 />} />
        <Route path='/Error' element={<Error />} />
        <Route path='/Careers' element={<Careers />} />
        <Route path='/Aboutus' element={<Aboutus />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/ZaheerIqbal' element={<ZaheerIqbal />} />
        <Route path='/Whatsapp' element={<Whatsapp />} />
      </Routes>
      <div>
        {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
        <Footer />
      </div>{" "}
    </div>
  );
}

export default App;
