import React, { useState, useEffect } from "react";

const CounterSection = () => {
  const [counters, setCounters] = useState([]);

  useEffect(() => {
    // Initialize counter data
    const data = [
      { target: 100 },
      { target: 223 },
      { target: 10 },
      { target: 17 },
    ];

    setCounters(data.map((c) => ({ ...c, count: 0 })));
  }, []);

  useEffect(() => {
    counters.forEach((counter, index) => {
      const interval = setInterval(() => {
        setCounters((prevCounters) => {
          const newCounters = [...prevCounters];

          if (newCounters[index].count < counter.target) {
            newCounters[index].count += 1;
          } else {
            clearInterval(interval);
          }

          return newCounters;
        });
      }, 600);
    });
  }, [counters]);

  return (
    <section className='counter-section'>
      {" "}
      <div className='counter'>
        {counters.map((counter, index) => (
          <div className='Data' key={index}>
            {" "}
            <h3>
              {" "}
              <span
                className='counter-number'
                value={100}
                data-target={counter.target}>
                {counter.count || 0}
              </span>
              {index === 0 ? "%" : index === 2 ? "K" : "+"}
            </h3>{" "}
            <h4>
              {index === 0
                ? "Data Accuracy"
                : index === 1
                ? "Projects Done"
                : index === 2
                ? "Happy Clients"
                : "Products"}
            </h4>{" "}
          </div>
        ))}
      </div>{" "}
    </section>
  );
};

export default CounterSection;
