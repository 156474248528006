import React from "react";
// import { Link } from "react-router-dom";
import "./about.css";

import CounterSection from "./CounterSection";
import Company from "./Companies";
import img1 from "../../assets/img/Aboutus/Rectangle812.png";
import img2 from "../../assets/img/Aboutus/Rectangle813.png";
import imglo from "./images/logo512.png";
import ceo from "../../assets/img/Aboutus/Ceo-kashif.jpeg";
import missionimg from "../../assets/img/Aboutus/missonimg.png";

function Aboutus() {
  return (
    <section id='About' className='container'>
      {/*  Top section Abouts Our Compnay left pic && top conteent  */}

      <section className='container'>
        <div className='parallel'>
          <div className='text-about'>
            <h2>About Our Company</h2>
            <h3>CLOUDLEM</h3>
            <p>
              Cloudlem is a reliable and trusted DevOps consulting company that
              helps clients succeed in the ever-changing world of technology.
              With a team of talented and experienced engineers, the company is
              committed to providing top-notch services, including cloud
              migration, deployment, automation, infrastructure, dockerization,
              security recovery planning, and execution, to help clients achieve
              their business objectives.
            </p>
          </div>
          <div className='tasveer'>
            <img src={img1} alt='picoo' />
          </div>
        </div>
        <div className='tasveer1'>
          <img src={img2} alt='jimaico' />
        </div>
      </section>

      {/*  2nd section Counter  */}
      <section className='counter-section'>
        <CounterSection />
      </section>

      {/*  Top section Trusted Companise */}
      <Company />
      {/*  Top section Abouts Our Compnay left pic && top conteent  */}
      <div className='OurMission'>
        <div className='ceo-container'>
          <div className='ceo-talk'>
            <h2>Our Mission</h2>
            <h4>
              We Inspire, Innovate, And Create Limitless Opportunities To Thrive
            </h4>
            <p>
              At Cloudlem, our mission is to provide premier DevOps and IT
              consulting services that empower our clients to thrive in the
              dynamic world of technology. We strive to deliver excellent
              solutions that help our clients achieve their goals by leveraging
              cutting-edge tools and methodologies. Our experienced and
              professionals are dedicated to developing and deploying robust and
              reliable software solutions that are secure, resilient, and
              scalable. We are committed to providing exceptional customer
              service and support and to working collaboratively with our
              clients to ensure their success. At Cloudlem, we are passionate
              about helping our clients realize their full potential and succeed
              in their respective markets.
            </p>
          </div>
          <div className='ceo-img'>
            <img src={ceo} alt='pic of CEO Kashi Naseem' />
            <div className='ceo-name'>
              <h3>Mr. Kashif Naseem</h3>
              <h5>CEO & Co-Founder</h5>
            </div>
          </div>
        </div>
      </div>

      {/*Our Story */}

      <div className='ourStory'>
        <div className='story-img'>
          <img src={missionimg} alt='team at cloudlem' />
        </div>
        <div className='story'>
          <h2>Our Story</h2>
          <h4>We Believe in the Power Of Unity And Team Work</h4>
          <p>
            In January 2022, Cloudlem was established to provide comprehensive
            DevOps services worldwide. Initially, the company had a modest staff
            of only 8 employees, but within a year, the number of employees grew
            to exceed 30. Cloudlem is the first company in Pakistan to
            specialize solely in DevOps services, demonstrating its commitment
            to this area of expertise. Due to market demand and advances in
            technology, Cloudlem was founded to serve people all over the world.
            <button className='readmore-btn'> Read more ...</button>
          </p>
        </div>
      </div>

      {/*  Top section Cloudlem TEAM  */}

      {/*  Top section Qoute */}
      <div className='quote-section'>
        <div className='company-logo'>
          <img src={imglo} alt='Company Logo' />
        </div>
        <div className='quote-content'>
          <p>
            "Devops shows how we optimize the IT value stream, converting
            business needs into capabilities and services that provide value for
            our customers."
          </p>
        </div>
      </div>
    </section>
  );
}
export default Aboutus;
