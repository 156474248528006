import React from "react";
// FrontEnd Images
import front1 from "../assets/img/Frontnd/angular.3f781d9105b3bef395676155dd37e208.svg";
import front2 from "../assets/img/Frontnd/bootstrap-4.997c463dca63c915b9a51f1caedef8e1.svg";
import front3 from "../assets/img/Frontnd/css-3.4e47c3122b8d2476a02f97dcfcb28640.svg";
import front4 from "../assets/img/Frontnd/HTML5 Logo.aff8a9cb398fc75e4f21dab1ba1e097e.svg";
import front5 from "../assets/img/Frontnd/jquery-1.a720425624c73a7db991c7b40927c65c.svg";
import front6 from "../assets/img/Frontnd/js.1707d09a3f6f31bb97e8.png";
import front7 from "../assets/img/Frontnd/react-2.e68b35f4774979ae220b1d5d7b345b00.svg";
import front8 from "../assets/img/Frontnd/tailwind.c1a90d1e4577b708caa7.png";
import front9 from "../assets/img/Frontnd/vue-js-1.4921ee763e7954a01fa7d9bc10d1ab93.svg";
import front10 from "../assets/img/Backend/redux.1edba70acbe0a2484e24.png";
// BackEnd images
import back1 from "../assets/img/Backend/go-6.4c88fbf92cd36c4790582de373b6ee4f.svg";
import back2 from "../assets/img/Backend/java-4.a351f739f47fda7a637b86ffa530d56a.svg";
import back3 from "../assets/img/Backend/NET_Logo.b4a1f214e0667e233ce63e039e7066ca.svg";
import back4 from "../assets/img/Backend/nodejs.e55572852ea7a91f785f9c3a2102771a.svg";
import back5 from "../assets/img/Backend/php.e0e7ce5b4ae22c86d648.png";
import back6 from "../assets/img/Backend/python-3.13d5b230d0e20e4775ddab47ba00602b.svg";

// Cloud Image
import Cloud1 from "../assets/img/Cloud/aws.svg";
import Cloud2 from "../assets/img/Cloud/azure.svg";
import Cloud3 from "../assets/img/Cloud/digitalocean-logo.744a69736c6a24a3a3debe11e39da702.svg";
import Cloud4 from "../assets/img/Cloud/google-cloud.ef65d7b6ca56ea32fa471fb49d3e79c3.svg";
import Cloud5 from "../assets/img/Cloud/ibm.78d435f03b38eaa63d7b9324ef02b328.svg";
import Cloud6 from "../assets/img/Cloud/oracle-6.b4e57c5818bba12da986fe91a3f7ccb5.svg";
//  Devops Image
import Devops1 from "../assets/img/DevOps/ansible.41bdec7525794a9f3f9bb20e938ab34e.svg";
import Devops2 from "../assets/img/DevOps/aws-cloudformation.cccd9c7cd1534e9061a7cae8af762135.svg";
import Devops3 from "../assets/img/DevOps/chef-13.018acee956010d4f491262e0b4651ee6.svg";
import Devops4 from "../assets/img/DevOps/docker.3253a6fe64f326e90f1716b7069eeeb8.svg";
import Devops5 from "../assets/img/DevOps/elastic-stack.6802dde19bb7822e69c5a4f83027b57a.svg";
import Devops6 from "../assets/img/DevOps/git.2a98f35035608049c6a8e28206c3e49e.svg";
import Devops7 from "../assets/img/DevOps/github-icon-1.e0d7d7254ac0545743fc7af09c1e977b.svg";
import Devops8 from "../assets/img/DevOps/gitlab.4a31f1b0d7169fcc66bc864a62521c31.svg";
import Devops9 from "../assets/img/DevOps/jenkins-1.b0b715f76efffd1589bcab6ca57943d6.svg";
import Devops10 from "../assets/img/DevOps/kubernets.3ade6bfa4b917a8227680e3f34cf6957.svg";
import Devops11 from "../assets/img/DevOps/prometheus.3e9d4662c20b7bb13c7dc0780fd660e4.svg";
import Devops12 from "../assets/img/DevOps/puppet-1.75dbadf64126ccf5a57c5591a4193bf4.svg";
import Devops13 from "../assets/img/DevOps/rails-1.05656c97e3e19a518d8d8543ab0b3896.svg";
import Devops14 from "../assets/img/DevOps/terraform-enterprise.76fc0c987b75018c742e027ad35c5239.svg";

// DataBase Images
import DataBase1 from "../assets/img/DataBase/casendra.4e70485a1ce60bc6c769.png";
import DataBase2 from "../assets/img/DataBase/ibmdb2.deb5cce222cb76ba5358.png";
import DataBase3 from "../assets/img/DataBase/mariadb.92f19a57b0716d8fb55a.png";
import DataBase4 from "../assets/img/DataBase/microsoft-sql-server-1.faf9645db7accb5d88a409189ad0a7d8.svg";
import DataBase5 from "../assets/img/DataBase/mongodb-icon-1.1aca075d5cce0819fea7ea0902d94ce4.svg";
import DataBase6 from "../assets/img/DataBase/oracledb.49954642e809ac51ae3c.png";
import DataBase7 from "../assets/img/DataBase/postgresql.ae92b576b8ea41666538c0756df27969.svg";
import DataBase8 from "../assets/img/DataBase/redis.ec8971a991f77974bf2f0dceb7359ac7.svg";
import DataBase9 from "../assets/img/DataBase/mysql-2.0c688de2f39a88350c7fd5ae4a90b5cf.svg";

const Technology = () => {
  return (
    <section className='TECHNOLOGIES' id='TECHNOLOGIES'>
      <div className='container'>
        <div className='section-title' data-aos='zoom-out'>
          <h2>TECHNOLOGIES</h2>
          <p style={{ marginTop: "40px", marginBottom: "40px" }}>
            Technology Stack
          </p>
          <section id='technology' className='technology'>
            <div className='container'>
              <ul className='nav nav-tabs  ' role='tablist'>
                <li
                  className='nav-item col'
                  data-aos='zoom-in'
                  role='presentation'>
                  <a
                    className='nav-link active show'
                    data-bs-toggle='tab'
                    href='#tab-31'
                    aria-selected='true'
                    role='tab'>
                    FrontEnd
                  </a>
                </li>
                <li
                  className='nav-item col'
                  data-aos='zoom-in'
                  data-aos-delay={100}
                  role='presentation'>
                  <a
                    className='nav-link'
                    data-bs-toggle='tab'
                    href='#tab-32'
                    aria-selected='false'
                    tabIndex={-1}
                    role='tab'>
                    BackEnd
                  </a>
                </li>
                <li
                  className='nav-item col'
                  data-aos='zoom-in'
                  data-aos-delay={200}
                  role='presentation'>
                  <a
                    className='nav-link'
                    data-bs-toggle='tab'
                    href='#tab-33'
                    aria-selected='false'
                    tabIndex={-1}
                    role='tab'>
                    Cloud
                  </a>
                </li>
                <li
                  className='nav-item col'
                  data-aos='zoom-in'
                  data-aos-delay={300}
                  role='presentation'>
                  <a
                    className='nav-link'
                    data-bs-toggle='tab'
                    href='#tab-34'
                    aria-selected='false'
                    tabIndex={-1}
                    role='tab'>
                    DevOps
                  </a>
                </li>
                <li
                  className='nav-item col'
                  data-aos='zoom-in'
                  data-aos-delay={300}
                  role='presentation'>
                  <a
                    className='nav-link'
                    data-bs-toggle='tab'
                    href='#tab-35'
                    aria-selected='false'
                    tabIndex={-1}
                    role='tab'>
                    DataBase
                  </a>
                </li>
              </ul>
              <ul className='tab-content' data-aos='fade-up'>
                <div
                  className='tab-pane active show'
                  id='tab-31'
                  role='tabpanel'>
                  <ul className='row'>
                    <li className='icons '>
                      <img
                        src={front7}
                        alt='react logo'
                        className='img-fulidd '
                      />
                      <img
                        src={front1}
                        alt='{features2}'
                        className='Angular logo '
                      />
                      <img
                        src={front2}
                        alt='{features2}'
                        className='bootstrap logo'
                      />
                      <img
                        src={front3}
                        alt='Css 3 logo'
                        className='img-fulidd '
                      />
                      <img
                        src={front4}
                        alt='{features2}'
                        className='img-fulidd '
                      />
                      <img
                        src={front5}
                        alt='{features2}'
                        className='img-fulidd '
                      />
                      <img
                        src={front6}
                        alt='{features2}'
                        className='img-fulidd '
                      />
                      <img
                        src={front8}
                        alt='{features2}'
                        className='img-fulidd '
                      />
                      <img
                        src={front9}
                        alt='{features9}'
                        className='img-fulidd '
                      />
                      <img
                        src={front10}
                        alt='{features10}'
                        className='img-fulidd'
                      />
                    </li>
                  </ul>
                </div>
                <div className='tab-pane' id='tab-32' role='tabpanel'>
                  <ul className='row'>
                    <li className='icons '>
                      <img
                        src={back1}
                        alt='{features3}'
                        className='img-fulidd '
                        style={{ width: "10%" }}
                      />
                      <img
                        src={back2}
                        alt='{features3}'
                        className='img-fulidd '
                      />
                      <img
                        src={back3}
                        alt='{features3}'
                        className='img-fulidd '
                      />
                      <img
                        src={back4}
                        alt='{features3}'
                        className='img-fulidd '
                      />
                      <img
                        src={back5}
                        alt='{features3}'
                        className='img-fulidd '
                        style={{ width: "10%" }}
                      />
                      <img
                        src={back6}
                        alt='{features3}'
                        className='img-fulidd '
                      />
                    </li>
                  </ul>
                </div>
                <div className='tab-pane' id='tab-33' role='tabpanel'>
                  <ul className='row'>
                    <li className=' icons '>
                      <img
                        src={Cloud1}
                        alt='{features4}'
                        className='cloud '
                        style={{ width: "8%" }}
                      />
                      <img
                        src={Cloud2}
                        alt='{features4}'
                        className='cloud '
                        style={{ width: "8%" }}
                      />
                      <img
                        src={Cloud3}
                        alt='{features4}'
                        className='cloud '
                        style={{ width: "8%" }}
                      />
                      <img
                        src={Cloud4}
                        alt='{features4}'
                        className='cloud '
                        style={{ width: "8%" }}
                      />
                      <img
                        src={Cloud5}
                        alt='{features4}'
                        className='cloud '
                        style={{ width: "8%" }}
                      />
                      <img
                        src={Cloud6}
                        alt='{features4}'
                        className='cloud '
                        style={{ width: "12%" }}
                      />
                    </li>
                  </ul>
                </div>
                <div className='tab-pane' id='tab-34' role='tabpanel'>
                  <ul className='row'>
                    <li className=' icons2 '>
                      <div className='icons'>
                        <img src={Devops1} alt='' className='img-fulidd ' />
                        <img src={Devops2} alt='' className='img-fulidd ' />
                        <img
                          src={Devops3}
                          alt=''
                          className='img-fulidd '
                          style={{ textAlign: "center" }}
                        />
                        <img src={Devops4} alt='' className='img-fulidd ' />
                        <img src={Devops5} alt='' className='img-fulidd ' />
                        <img src={Devops6} alt='' className='img-fulidd ' />
                        <img src={Devops7} alt='' className='img-fulidd ' />
                        <img src={Devops8} alt='' className='img-fulidd ' />
                        <img src={Devops9} alt='' className='img-fulidd ' />
                      </div>
                      <div className='icons'>
                        <img src={Devops10} alt='' className='img-fulidd ' />
                        <img src={Devops11} alt='' className='img-fulidd ' />
                        <img src={Devops12} alt='' className='img-fulidd ' />
                        <img src={Devops13} alt='' className='img-fulidd ' />
                        <img src={Devops14} alt='' className='img-fulidd ' />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className='tab-pane' id='tab-35' role='tabpanel'>
                  <ul className='row'>
                    <li className=' icons2  '>
                      <div className=' icons '>
                        <img
                          src={DataBase1}
                          alt='{features4}'
                          className='cloud '
                        />
                        <img
                          src={DataBase2}
                          alt='{features4}'
                          className='cloud '
                        />
                        <img
                          src={DataBase3}
                          alt='{features4}'
                          className='cloud '
                        />
                        <img
                          src={DataBase4}
                          alt='{features4}'
                          className='cloud '
                        />
                        <img
                          src={DataBase5}
                          alt='{features4}'
                          className='cloud '
                        />
                        <img
                          src={DataBase6}
                          alt='{features4}'
                          className='cloud '
                        />
                        <img
                          src={DataBase7}
                          alt='{features4}'
                          className='cloud '
                        />
                      </div>
                      <div className=' icons '>
                        <img
                          src={DataBase8}
                          alt='{features4}'
                          className='cloud '
                        />
                        <img
                          src={DataBase9}
                          alt='{features4}'
                          className='cloud '
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Technology;
