import React, { useState } from "react";
import Feature1 from "../assets/img/features-1.png";
import Feature2 from "../assets/img/features-2.png";
import Feature3 from "../assets/img/features-3.png";
import Feature4 from "../assets/img/features-4.png";

const Feature = () => {
  const [isListVisible, setIsListVisible] = useState(false);

  const handleClick = () => {
    setIsListVisible(!isListVisible);
  };
  return (
    <section id='features' className='features'>
      <div className='container'>
        <ul className='nav nav-tabs  flex' role='tablist'>
          <li className='nav-item col-3' data-aos='zoom-in' role='presentation'>
            <a
              className='nav-link active show'
              data-bs-toggle='tab'
              href='#tab-1'
              aria-selected='true'
              role='tab'>
              <i className='fa-solid fa-spinner' />
              <h4 className='d-none d-lg-block' style={{ fontSize: "0.8rem" }}>
                DevOps &amp; DevsecOps Engineers
              </h4>
            </a>
          </li>
          <li
            className='nav-item col-3'
            data-aos='zoom-in'
            data-aos-delay={100}
            role='presentation'>
            <a
              className='nav-link'
              data-bs-toggle='tab'
              href='#tab-2'
              aria-selected='false'
              tabIndex={-1}
              role='tab'>
              <i className='fa-solid fa-child-reaching' />
              <h4 className='d-none d-lg-block'>Site Reliability Engineers</h4>
            </a>
          </li>
          <li
            className='nav-item col-3'
            data-aos='zoom-in'
            data-aos-delay={200}
            role='presentation'>
            <a
              className='nav-link'
              data-bs-toggle='tab'
              href='#tab-3'
              aria-selected='false'
              tabIndex={-1}
              role='tab'>
              <i className='fa-regular fa-sun' />
              <h4 className='d-none d-lg-block'>Cloud Consultants</h4>
            </a>
          </li>
          <li
            className='nav-item col-3'
            data-aos='zoom-in'
            data-aos-delay={300}
            role='presentation'>
            <a
              className='nav-link'
              data-bs-toggle='tab'
              href='#tab-4'
              aria-selected='false'
              tabIndex={-1}
              role='tab'>
              <i className='fa-solid fa-store' />
              <h4 className='d-none d-lg-block'>Software Architectures</h4>
            </a>
          </li>
        </ul>
        <div className='tab-content' data-aos='fade-up'>
          <div className='tab-pane active show' id='tab-1' role='tabpanel'>
            <div className=' flex'>
              <div className='col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0'>
                <h3>DevOps and DevSecOps Engineers.</h3>
                <p>
                  DevOps is a set of&nbsp;practices&nbsp;tools and
                  a&nbsp;cultural philosophy that automates and integrates the
                  processes between software development and IT teams. It
                  emphasizes team empowerment, cross-team communication and
                  collaboration, and technology automation.
                  <br /> DevSecOps engineers are the professionals responsible
                  for bringing development, security, and operations together to
                  enhance the security stance of the organization. They monitor
                  and automate security processes and test systems. This results
                  in the protection of data and information technology (IT)
                  infrastructure.
                </p>
                <ul>
                  <div>
                    <h3>
                      {" "}
                      Key duties of DevSecOps engineers:
                      <button
                        onClick={handleClick}
                        className='btnnn'
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}>
                        <i className='bi bi-chevron-double-down' />
                      </button>
                    </h3>
                    {isListVisible && (
                      <ul>
                        <li>
                          <i class='bi bi-check-all'></i> Process monitoring
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> Writing risk analyzes
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Incident management
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Testing, selection and
                          implementation of technologies, tools and working
                          methods
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Automation of security
                          controls
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> The maintenance of the
                          system and of the external and internal computer
                          network of the company
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Control and management
                          of security operations
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> More broadly, they
                          participate in the construction of a “safety culture”
                          within the company by supporting the various teams and
                          customers in the implementation of good safety
                          practices.
                        </li>
                      </ul>
                    )}
                  </div>
                </ul>
              </div>
              <div className='col-lg-6 order-1 order-lg-2 text-center'>
                <img src={Feature1} alt='' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='tab-pane' id='tab-2' role='tabpanel'>
            <div className='flex justify-between'>
              <div className='col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0'>
                <h3>Site reliability engineer</h3>
                <p>
                  The dependability, scalability, and performance of a company's
                  technological infrastructure and services are the
                  responsibility of site reliability engineers (SREs). System
                  and service availability: ensuring the systems and services of
                  the business are highly available and reliable. Automation:
                  decreasing downtime, automating repetitive operations, and
                  improving productivity.
                </p>
                <ul>
                  <div>
                    <h3>
                      {" "}
                      Key duties of SREs include the following:
                      <button
                        onClick={handleClick}
                        className='btnnn'
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}>
                        <i className='bi bi-chevron-double-down' />
                      </button>
                    </h3>
                    {isListVisible && (
                      <ul>
                        <li>
                          <i class='bi bi-check-all'></i> Process monitoring
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> Writing risk analyzes
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Incident management
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Testing, selection and
                          implementation of technologies, tools and working
                          methods
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Automation of security
                          controls
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> The maintenance of the
                          system and of the external and internal computer
                          network of the company
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Control and management
                          of security operations
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> More broadly, they
                          participate in the construction of a “safety culture”
                          within the company by supporting the various teams and
                          customers in the implementation of good safety
                          practices.
                        </li>
                      </ul>
                    )}
                  </div>
                </ul>
              </div>
              <div className='mx-8  col-lg-6 order-1 order-lg-2 text-center'>
                <img src={Feature2} alt='img-2-feature' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='tab-pane' id='tab-3' role='tabpanel'>
            <div className='flex'>
              <div className='col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0'>
                <h3>Cloud consultant.</h3>
                <p>
                  Cloud consultants help businesses design, execute and manage
                  cloud systems to enhance efficiency, flexibility, and safety.
                  A consultant usually helps businesses in the transformation
                  from a hardware-based infrastructure to a cloud-based system.
                  In these situations consultant looks after a company's
                  business and technology goals, selects appropriate cloud
                  technology, and works to make a sure seamless transformation.
                </p>
                <ul>
                  <div>
                    <h3>
                      {" "}
                      Key duties are:
                      <button
                        onClick={handleClick}
                        className='btnnn'
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}>
                        <i className='bi bi-chevron-double-down' />
                      </button>
                    </h3>
                    {isListVisible && (
                      <ul>
                        <li>
                          <i class='bi bi-check-all'></i> Process monitoring
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> Writing risk analyzes
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Incident management
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Testing, selection and
                          implementation of technologies, tools and working
                          methods
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Automation of security
                          controls
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> The maintenance of the
                          system and of the external and internal computer
                          network of the company
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Control and management
                          of security operations
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> More broadly, they
                          participate in the construction of a “safety culture”
                          within the company by supporting the various teams and
                          customers in the implementation of good safety
                          practices.
                        </li>
                      </ul>
                    )}
                  </div>
                </ul>
              </div>
              <div className='col-lg-6 order-1 order-lg-2 text-center'>
                <img src={Feature3} alt='' className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='tab-pane' id='tab-4' role='tabpanel'>
            <div className='flex'>
              <div className='col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0'>
                <h3>Software architecture</h3>
                <p>
                  A system's software architecture shows how the system is
                  organized or structured and explains its behavior. A system is
                  the aggregate of parts that carry out a single function or set
                  of related functions. In other words, the software
                  architecture offers a reliable basis for the development of
                  software.
                </p>
                <ul>
                  <div>
                    <h3>
                      {" "}
                      KEY responsibilities:
                      <button
                        onClick={handleClick}
                        className='btnnn'
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}>
                        <i className='bi bi-chevron-double-down' />
                      </button>
                    </h3>
                    {isListVisible && (
                      <ul>
                        <li>
                          <i class='bi bi-check-all'></i> Process monitoring
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> Writing risk analyzes
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Incident management
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Testing, selection and
                          implementation of technologies, tools and working
                          methods
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Automation of security
                          controls
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> The maintenance of the
                          system and of the external and internal computer
                          network of the company
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i>Control and management
                          of security operations
                        </li>
                        <li>
                          <i class='bi bi-check-all'></i> More broadly, they
                          participate in the construction of a “safety culture”
                          within the company by supporting the various teams and
                          customers in the implementation of good safety
                          practices.
                        </li>
                      </ul>
                    )}
                  </div>
                </ul>
              </div>
              <div className='col-lg-6 order-1 order-lg-2 text-center'>
                <img src={Feature4} alt='' className='img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
