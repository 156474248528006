import React from "react";
import "../assets/css/blogstyle.css";
import ScrollToTop from "../components/scrolltotop";
// images/blog
import Devops from "../assets/img/blog/Devops.a1cf45330e8ab54e6d56.jpg";
import Consultancy from "../assets/img/blog/Consultancy.7cfb410322ed6c289f34.jpg";
import development from "../assets/img/blog/development.727e14272d3bfc2dd8c7.jpg";
import uxui from "../assets/img/blog/uxui.3d1440103d5db3e66637.jpg";
import docker from "../assets/img/blog/docker";
import AWS from "../assets/img/blog/AWS.jpg";
import GITLAB from "../assets/img/blog/GitLab.jpg";
import RECNAV from "../assets/img/blog/Native.jpg";
import cloud from "../assets/img/blog/cloud.jpg";
import { Link } from "react-router-dom";

function Blog() {
  // window.scroll(0, 0);
  return (
    <div id='blog' className='blog'>
      <ScrollToTop />
      <div className='container'>
        <div className='title' data-aos='zoom-out'>
          <h2>Cloudlem Blogs</h2>
          <p style={{ marginTop: "40px" }}>
            The Place For DevOps, Cloudlem News And Updates
          </p>
        </div>
        <div className='d-flex gap-4 justify-content-around'>
          <div className='flex-wrap'>
            {/*Line one*/}
            <div className='col '>
              {" "}
              <div className=' col mt-3 mb-5 pb-5'>
                <img
                  src={Devops}
                  alt=''
                  style={{
                    borderRadius: "20px",
                  }}
                />
                <h6>DATE 21/05/2022</h6>
                <h3>The ultimate guide to Devops</h3>
                <p
                  id='bodydesc'
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "293px",
                    overflowY: "scroll",
                  }}>
                  DevOps is the combination of two terms development and
                  operations meant to present a collective or shared perspective
                  to perform tasks through a companys application development
                  and IT. The primary purpose of DevOps is to provide a
                  sustainable infrastructure for specific applications while
                  ensuring high scalability. Scalable apps, after all, are
                  extremely dependable, automated, and essential in todays
                  software development process. DevOps affects the application
                  life cycle, during its complete life cycle few steps are
                  mandatory like planning, development, delivery, and operative
                  stages. As each stage depends on other stages. All phases are
                  involved in the DevOps culture process. DevOps provides a
                  solution at a time when every technology leader is faced with
                  enabling security, reliability, agility, dealing with security
                  breaches, improving time to market, and undergoing huge
                  technological transformations. An inherent tension can exist
                  between Development and Operations, resulting in delayed time
                  to market for new products and features, poor quality, higher
                  outages and technical debt, reduced engineering productivity,
                  and increased staff unhappiness and burnout. . DevOps ideas
                  and patterns enable teams to resolve this fundamental, ongoing
                  conflict. DevOps may necessitate new cultural and management
                  norms, as well as changes in technical practices and
                  architecture. As a result, developer productivity is
                  maximized, organizational learning is enhanced, employee
                  satisfaction is high, and the ability to compete in the
                  marketplace is enhanced.
                </p>
                <div>
                  <Link to='/Devops'>
                    <a Linkto='./Devops.jsx' className='read-more' href='/'>
                      Read More ...
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class='col'>
              <div className='col'>
                <img
                  src={Consultancy}
                  alt=''
                  style={{ borderRadius: "20px" }}
                />
                <h6>DATE 17/08/2022</h6>
                <h3>Devops Consultancy</h3>
                <p
                  id='bodydesc'
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "293px",
                    overflowY: "scroll",
                  }}>
                  DevOps consultancy is a service that assists organizations in
                  optimizing their software development and IT operations
                  processes to deliver applications and services more quickly,
                  with higher quality and with lower risk. A DevOps consultant
                  collaborates with clients to understand their unique needs,
                  goals, and challenges and then provides expertise and guidance
                  to assist them in implementing DevOps practices and tools. The
                  ultimate goal of DevOps consultancy is to help...
                  organizations in increasing the speed, agility, and
                  reliability of their software delivery pipeline, allowing them
                  to deliver better software more quickly. Some of the most
                  common areas of focus for DevOps consulting are
                  <ul>
                    <li>
                      Automation: The process of automating tasks, processes,
                      and workflows to reduce manual effort and increase
                      efficiency.
                    </li>
                    <li>
                      Continuous Integration and Continuous Delivery (CI/CD):
                      Building, testing, and deploying software using automated
                      pipelines.
                    </li>
                    <li>
                      Infrastructure as Code (IaC): Managing infrastructure
                      through code instead of manual configuration.
                    </li>
                    <li>
                      {" "}
                      Monitoring and logging: implementing in place tools and
                      processes to keep track of the performance and
                      availability of applications and services.
                    </li>
                    <li>
                      Security: Putting in place security best practices and
                      tools to protect software development and delivery
                      processes.
                    </li>
                    <li>
                      Security: Putting in place security best practices and
                      tools to protect software development and delivery
                      processes.
                    </li>
                    <li>
                      <strong> Culture and Collaboration</strong> Enhancing
                      collaboration and communication between development and
                      operations teams to foster a more DevOps-friendly culture.
                    </li>
                  </ul>
                </p>
                <div>
                  <Link to='/DevopsCons'>
                    <a Linkto='./DevopsCons.jsx' className='read-more' href='/'>
                      Read More ...
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class='col'>
              <div className='col'>
                <img
                  src={development}
                  alt=''
                  style={{ borderRadius: "20px" }}
                />
                <h6>DATE 01/01/2023</h6>
                <h3>Deployment</h3>
                <p
                  id='bodydesc'
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "293px",
                    overflowY: "scroll",
                  }}>
                  Deployment is the process of delivering and installing
                  software updates, applications, or services to a production
                  environment. It is an essential step in the software
                  development life cycle, as it makes the new or updated
                  software available to users. The deployment process typically
                  consists of several steps, including testing, environment
                  preparation, and software release. Before deploying software
                  to a production environment, it must be thoroughly tested to
                  ensure that it functions properly and meets the requirements.
                  When the testing is finished, the environment must be prepared
                  to receive the new or updated software. It may be necessary to
                  update configuration files, prepare databases, and configure
                  security settings. There are various deployment methods,
                  including manual deployment, automated deployment, and
                  continuous deployment. Manual deployment involves manual steps
                  and is time-consuming and error-prone. Automated deployment,
                  on the other hand, automates the deployment process, reducing
                  the risk of errors and speeding up the deployment time.
                  Continuous deployment takes the automation of the deployment
                  process to the next level by continuously delivering updates
                  to the production environment as soon as they are ready.
                  Deployment is a critical step in the software development life
                  cycle for delivering software updates and applications to
                  users. Businesses can reduce the risk of errors and shorten
                  the deployment time by automating the deployment process,
                  resulting in more efficient and reliable software update
                  delivery.
                </p>
                <div>
                  <Link to='/Deploy'>
                    <a Linkto='./Deployment.jsx' className='read-more' href='/'>
                      Read More ...
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*Line two*/}
          <div className='row mb-5'>
            <div class='col'>
              {" "}
              <div
                className='col '
                id='bodydesc'
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "736px",
                }}>
                <img src={uxui} alt='' style={{ borderRadius: "20px" }} />
                <h6>DATE 05/11/2022</h6>
                <h3>The definitive guide to UXUI</h3>
                <p
                  id='bodydesc'
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "293px",
                    overflowY: "scroll",
                  }}>
                  User interface (UI) and user experience (UX) design are
                  crucial factors in determining a products success.
                  Understanding user needs and producing a product that
                  satisfies them in a natural and user-friendly way is the
                  process of user experience design (UX). On the other hand, UI
                  design is in charge of the products visual design and
                  presentation, including its layout, typography, and color
                  scheme. UX and UI go hand in hand because while a beautiful UI
                  design can draw in and engage users, a good UX design can
                  improve customer satisfaction and boost brand loyalty.
                  Together, they give the user a seamless and satisfying
                  experience. Businesses must devote resources to UI and UX
                  design.
                </p>
                <div>
                  <Link to='/Uxui'>
                    <a Linkto={"./Uxui.jsx"} className='read-more' href='/'>
                      Read More ...
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class='col'>
              <div
                className='col '
                id='bodydesc'
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "736px",
                }}>
                <img src={docker} alt='' style={{ borderRadius: "20px" }} />
                <h6>DATE 05/11/2022</h6>
                <h3>The definitive guide to Docker</h3>
                <p
                  id='bodydesc'
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "293px",
                    overflowY: "scroll",
                  }}>
                  Docker is a powerful tool that has revolutionized the way
                  developers and operations teams build and deploy software
                  applications. With Docker, developers can package their
                  applications along with their dependencies into a container
                  that can run on any platform, eliminating the need for manual
                  installation and configuration of software components. This
                  makes it easier to develop and deploy applications faster and
                  with greater consistency. In the DevOps world, Docker has
                  become an essential tool for creating a seamless pipeline for
                  application development, testing, and deployment. By using
                  Docker containers, developers and operations teams can work
                  together more efficiently, without worrying about dependencies
                  or the underlying infrastructure. Docker's flexibility and
                  portability enable DevOps teams to deploy applications faster
                  and with greater reliability, making it an essential tool for
                  any modern software development team. In conclusion, Docker is
                  a game-changing technology that has transformed the way
                  software is developed and deployed. By enabling greater
                  collaboration between developers and operations teams, it has
                  become an essential tool for DevOps service. As the technology
                  continues to evolve, it will undoubtedly play an even more
                  significant role in the software development process in the
                  years to come.
                </p>
                <div>
                  <Link to='/Error'>
                    <a Linkto='./ErrorPage.jsx' className='read-more' href='/'>
                      Read More ...
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class='col'>
              <div
                className='col '
                id='bodydesc'
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "736px",
                }}>
                <img src={AWS} alt='' style={{ borderRadius: "20px" }} />
                <h6>DATE 05/11/2022</h6>
                <h3>The definitive guide to AWS </h3>
                <p
                  id='bodydesc'
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "293px",
                    overflowY: "scroll",
                  }}>
                  AWS (Amazon Web Services) is a cloud computing platform that
                  provides a wide range of services to businesses of all sizes.
                  It enables businesses to run their applications and store data
                  on a highly scalable and secure infrastructure, allowing them
                  to focus on their core competencies without worrying about the
                  underlying technology. AWS provides a range of services,
                  including compute, storage, networking, security, machine
                  learning, analytics, and much more. These services can be
                  tailored to meet the specific needs of businesses, providing
                  the flexibility and scalability required to grow and evolve.
                  Overall, AWS has become an essential tool for businesses
                  looking to improve their agility, reduce costs, and stay
                  competitive in today's rapidly changing market. As the
                  technology continues to evolve, it will undoubtedly play an
                  even more significant role in the future of computing.
                </p>
                <div>
                  <Link to='/Error'>
                    <a Linkto='./ErrorPage.jsx' className='read-more' href='/'>
                      Read More ...
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*Line three*/}
          <div className='row mb-5'>
            <div class='col'>
              <div
                className='col '
                id='bodydesc'
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "736px",
                }}>
                <img src={GITLAB} alt='' style={{ borderRadius: "20px" }} />
                <h6>DATE 05/11/2022</h6>
                <h3>The definitive guide to Gitlab </h3>
                <p
                  id='bodydesc'
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "293px",
                    overflowY: "scroll",
                  }}>
                  GitLab is a web-based Git repository manager that provides a
                  full-fledged platform for software development lifecycle
                  management. With GitLab, developers can easily manage their
                  code, collaborate with others, and automate their workflows.
                  One of the key features of GitLab is its continuous
                  integration and continuous deployment (CI/CD) pipeline. This
                  pipeline automates the process of building, testing, and
                  deploying code, reducing the risk of human error and
                  increasing the speed of software delivery. In addition to its
                  robust CI/CD capabilities, GitLab also offers built-in project
                  management tools, issue tracking, and code review features.
                  This makes it a one-stop-shop for all aspects of the software
                  development process. Overall, GitLab is a powerful and
                  comprehensive solution for software development teams of all
                  sizes. Its user-friendly interface and feature-rich
                  functionality make it a popular choice for organizations
                  looking to streamline their development workflows.
                </p>
                <div>
                  <Link to='/Error'>
                    <a Linkto='./ErrorPage.jsx' className='read-more' href='/'>
                      Read More ...
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class='col'>
              <div
                className='col '
                id='bodydesc'
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "736px",
                }}>
                <img src={RECNAV} alt='' style={{ borderRadius: "20px" }} />
                <h6>DATE 05/11/2022</h6>
                <h3>The definitive guide to React and React Native </h3>
                <p
                  id='bodydesc'
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "293px",
                    overflowY: "scroll",
                  }}>
                  React and React Native are two of the most popular open-source
                  JavaScript libraries for building user interfaces. React is
                  primarily used for building web applications, while React
                  Native is used for building mobile applications. One of the
                  key advantages of React is its component-based architecture,
                  which allows developers to create reusable UI components that
                  can be used across multiple pages or applications. This not
                  only saves development time but also ensures consistency in
                  design and functionality. React Native, on the other hand,
                  allows developers to build native mobile applications for iOS
                  and Android using the same React syntax. This means that
                  developers can write code once and deploy it to both
                  platforms, reducing development time and costs. Overall, React
                  and React Native are powerful and flexible tools for building
                  high-performance user interfaces for web and mobile
                  applications. Their popularity among developers and the
                  growing community support make them a go-to choice for modern
                  web and mobile app development.
                </p>
                <div>
                  <Link to='/Error'>
                    <a Linkto='./ErrorPage.jsx' className='read-more' href='/'>
                      Read More ...
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class='col'>
              <div
                className='col '
                id='bodydesc'
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "736px",
                }}>
                <img src={cloud} alt='' style={{ borderRadius: "20px" }} />
                <h6
                  style={{
                    textAlign: "right",
                    opacity: "0.5",
                    marginTop: "10px",
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    letterSpacing: "0em",
                    color: " #EF6603",
                  }}>
                  DATE 05/11/2022
                </h6>
                <h3>The definitive guide to Cloud-based services </h3>
                <p
                  id='bodydesc'
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "293px",
                    overflowY: "scroll",
                  }}>
                  Cloud-based services are becoming increasingly popular for
                  businesses and individuals who want to access computing
                  resources over the internet. These services provide a range of
                  benefits, including flexibility, scalability, and
                  cost-efficiency. Cloud-based services are available in a
                  variety of forms, including Infrastructure as a Service
                  (IaaS), Platform as a Service (PaaS), and Software as a
                  Service (SaaS). IaaS provides the basic computing
                  infrastructure, such as virtual machines and storage, while
                  PaaS provides a platform for building and deploying
                  applications. SaaS provides complete software applications
                  that are accessible through the internet. Cloud-based services
                  also offer a range of security features, such as data
                  encryption and access controls, to ensure the protection of
                  sensitive information. Overall, cloud-based services offer
                  numerous benefits for businesses and individuals, including
                  increased flexibility, scalability, cost savings, and enhanced
                  security.
                </p>
                <div>
                  <Link to='/Error'>
                    <a Linkto='./ErrorPage.jsx' className='read-more' href='/'>
                      Read More ...
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
