import React from "react";
import "./Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeBranch,
  faCloud,
  faDesktop,
  faServer,
  faDatabase,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";

const servicesData = [
  {
    title: "DevOps Cycle",
    description: "Description for DevOps Cycle",
    icon: faCodeBranch,
  },
  {
    title: "Hybrid Multi Cloud Services",
    description: "Description for Hybrid Multi Cloud Services",
    icon: faCloud,
  },
  {
    title: "Product Design Development",
    description: "Description for Product Design Development",
    icon: faDesktop,
  },
  {
    title: "Server Management",
    description: "Description for Server Management",
    icon: faServer,
  },
  {
    title: "Database Administration",
    description: "Description for Database Administration",
    icon: faDatabase,
  },
  {
    title: "Cloud Managed Services",
    description: "Description for Cloud Managed Services",
    icon: faCogs,
  },
];

const ServiceCard = ({ title, description, icon }) => {
  return (
    <div className=' p-6 rounded-lg shadow-md flex flex-col items-center icon-box'>
      <FontAwesomeIcon icon={icon} className='text-5xl mb-4 the-icons' />
      <h3 className='text-xl font-semibold mb-2'>{title}</h3>
    </div>
  );
};

const Service = () => {
  const topServices = servicesData.slice(0, 3);
  const bottomServices = servicesData.slice(3);

  return (
    <section id='services ' className='services Max-container'>
      <div className='section-title' data-aos='zoom-out'>
        <h2>Services</h2>
        <p style={{ marginTop: "40px" }}>What we do </p>
      </div>
      <div className='row md:grid-cols-2  gap-6'>
        <div className='flex md:grid-cols-1 gap-6 '>
          {topServices.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              description={service.description}
              icon={service.icon}
            />
          ))}
        </div>
        <div className='flex md:grid-cols-1 gap-6'>
          {bottomServices.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              description={service.description}
              icon={service.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;
