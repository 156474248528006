import { Component } from "react";

class FaqItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleFaq = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { question, answers } = this.props;
    const { isOpen } = this.state;

    return (
      <li>
        <div
          className={`question ${isOpen ? "open" : "closed"}`}
          onClick={this.toggleFaq}>
          <span
            className={`icons ${isOpen ? "open" : "closed"}`}
            onClick={this.toggleFaq}>
            {isOpen ? (
              <i className='bi bi-dash ' />
            ) : (
              <i className='bi bi-plus ' />
            )}
          </span>
          {question}
        </div>
        {isOpen && (
          <div className='answer'>
            {answers.map((answer, index) => (
              <p key={index}>{answer}</p>
            ))}
          </div>
        )}
      </li>
    );
  }
}

const Faq = () => {
  const faqData = [
    {
      question: "What is Devops?",
      answers: [
        "DevOps is a set of practices and tools aimed at improving the collaboration and communication between development and operations teams, with the goal of delivering software faster and more reliably.",
      ],
    },
    {
      question: "What services do you offer in Devops?",
      answers: [
        "Our DevOps services include Continuous Integration and Continuous Delivery (CI/CD), Infrastructure as Code (IaC), Cloud Management, Monitoring and Logging, Security, and Automated Testing.",
      ],
    },
    {
      question:
        "What is Continuous Integration and Continuous Delivery (CI/CD)?",
      answers: [
        "CI/CD is a software development practice that involves        automating the process of integrating code changes into a        code-base and testing and deploying code changes to production.",
      ],
    },
    {
      question: "  What is Infrastructure as Code?",
      answers: [
        " IaC is a practice of managing infrastructure as code, using        configuration files rather than manual processes. This enables        version control, automation, and reduces the risk of errors.",
      ],
    },
    {
      question: "What are the benefits of using Devops for cloud management?",
      answers: [
        " Using DevOps for cloud management enables faster and more        reliable deployment, scalability, and cost optimization. It        also provides a better understanding of resource utilization        and improved disaster recovery.",
      ],
    },

    {
      question: " What are the benefits of monitoring and logging in Devops?",
      answers: [
        "Monitoring and logging provide real-time insights into the    performance and health of systems and applications, enabling    early detection and resolution of problems. This improves the    overall stability and reliability of systems.",
      ],
    },
    {
      question: " How does Devops improve security?",
      answers: [
        " DevOps incorporates security into the development process,    lowering the risk of vulnerabilities and facilitating quicker    repair. This promotes compliance and prevents security    breaches.",
      ],
    },
    {
      question: "  What is the difference between UI and UX design?",
      answers: [
        " DevOps incorporates security into the development process,    lowering the risk of vulnerabilities and facilitating quicker    repair. This promotes compliance and prevents security    breaches.",
      ],
    },
    {
      question: "What is the difference between UI and UX design?",
      answers: [
        "UI (User Interface) design refers to the visual and    interactive elements of a website or application, such as    buttons, icons, and layout. UX (User Experience) design    focuses on the overall experience of using a product,    including how easy it is to use, how efficient the workflow    is, and how satisfied users are with the product.",
      ],
    },
    {
      question: " What are the benefits of automated testing in Devops?",
      answers: [
        "Automated testing reduces the time and effort required to    manually test applications, increases the speed of feedback    loops, and improvesss the overall quality of software. It also    helps to catch bugs early in the development process.",
      ],
    },
    {
      question:
        "How do you ensure the quality of your web development projects?",
      answers: [
        " We use a combination of industry-standard development    practices, thorough testing, and regular review and feedback    from clients to ensure the quality of our web development    projects.",
      ],
    },
    {
      question: "What services do you offer in web development?",
      answers: [
        "Our web development services include custom web development, e-commerce development, and responsive web design.",
      ],
    },
    {
      question:
        " What is cloud consultation and how can it benefit my business?",

      answers: [
        "The benefits of cloud consultation for businesses are numerous, including:",
        "1. Cost Savings: By using cloud services, businesses can save money on hardware, software, and IT support.",
        "2. Scalability: The cloud can grow or shrink as needed, making it easier for businesses to accommodate changes in demand for services.",
        "3. Enhanced Collaboration: Cloud-based services enable employees to collaborate more effectively, regardless of location.",
        "4. Improved Data Security: Cloud providers typically have advanced security measures in place to protect data, reducing the risk of data loss or theft.",
        "5. Accessibility: Cloud services can be accessed from anywhere with an internet connection, allowing for greater flexibility and remote work options.",
      ],
    },

    // Add more FAQ items as needed
  ];

  return (
    <div>
      <section id='faq' className='faq'>
        <div className='container'>
          <div className='section-title' data-aos='zoom-out'>
            <h2>F.A.Q</h2>
            <p style={{ marginTop: "40px" }}>Frequently Asked Questions</p>
          </div>
          <ul className='faq-list'>
            {faqData.map((faq, index) => (
              <FaqItem
                key={index}
                question={faq.question}
                answers={faq.answers}
              />
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Faq;
