import React from "react";
import "./Services.css";
import img1 from "../assets/img/services/Group 987.png";
import img2 from "../assets/img/services/CloudApp.png";
import img3 from "../assets/img/services/Vector.png";
import img4 from "../assets/img/services/Group 989.png";
import img5 from "../assets/img/services/Vector-1.png";
import img6 from "../assets/img/services/Group 988.png";

const Item = ({ imageSrc, heading, listItems }) => (
  <div className='Item'>
    <img src={imageSrc} alt='Item' />
    <div className='Content'>
      <h3>{heading}</h3>
      <ul>
        {listItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  </div>
);
const Services = () => {
  const items = [
    {
      imageSrc: img1,
      heading: "DevOps Services",
      listItems: [
        "DevOps Consulting Services",
        "Continuous Integration and Delivery",
        "Hire DevOps Engineers",
        "DevOps Tools and Technologies",
        "Offshore DevOps Team",
      ],
    },
    {
      imageSrc: img2,
      heading: "Hybrid & Multi Cloud Services",
      listItems: [
        "Cloud Consulting Services",
        "AWS Cloud Consulting Services",
        "Azure Cloud Consulting Services",
        "Google Cloud Consulting Services",
        "Digital Ocean Cloud Consulting Services",
      ],
    },
    {
      imageSrc: img3,
      heading: "Product Design  & Development",
      listItems: [
        "Web Application Development",
        "Micro services & API",
        "Data Analytics",
        "QA & Testing",
        "Native App Development",
      ],
    },
    {
      imageSrc: img4,
      heading: "Server Managment",
      listItems: [
        "Server Setup and Configuration",
        "Server Monitoring and maintenance",
        "Security Management",
        "Technical Support",
        "Backup and disaster recovery",
      ],
    },
    {
      imageSrc: img5,
      heading: "Data Base Administration",
      listItems: [
        "Database installation and configuration",
        "Performance tuning and optimization",
        "Data migration",
        "Database design and maintenance",
        "Technical support",
      ],
    },
    {
      imageSrc: img6,
      heading: "Cloud Managed Services",
      listItems: [
        "Cloud migration",
        "Cloud infrastructure management",
        "Cloud security management",
        "Cloud monitoring and optimization",
        "Cloud application management",
      ],
    },
    // Add more items here...
  ];
  return (
    <div className='container mt-8' style={{ marginTop: "8%" }}>
      <div className='section-title' data-aos='zoom-out'>
        <h2 style={{ fontSize: "2rem" }}>Services</h2>
        <p style={{ marginTop: "40px" }}>What we do </p>
      </div>
      <div className='container-service'>
        {items.map((item, index) => (
          <Item
            key={index}
            imageSrc={item.imageSrc}
            heading={item.heading}
            listItems={item.listItems}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;

// {
/*


<div className='Service' id='Service'>
      <div>
        <div className='head-items'>
          <img src='imgs' alt='logos-DevOps' />
          <h2> DevOps </h2>
        </div>
        <div className='Service-list'>
          <li>DevOps Consulting Services</li>
          <li>Continuous Integration and Delivery</li>
          <li>Hire DevOps Engineers</li>
          <li>DevOps Tools and Technologies</li>
          <li>Offshore DevOps Team</li>
        </div>
      </div>
      <div>
        <div className='head-items'>
          <img src='imgs' alt='logos-DevOps' />
          <h2> DevOps </h2>
        </div>
        <div className='Service-list'>
          <li>DevOps Consulting Services</li>
          <li>Continuous Integration and Delivery</li>
          <li>Hire DevOps Engineers</li>
          <li>DevOps Tools and Technologies</li>
          <li>Offshore DevOps Team</li>
        </div>
      </div>
      <div>
        <div className='head-items'>
          <img src='imgs' alt='logos-DevOps' />
          <h2> DevOps </h2>
        </div>
        <div className='Service-list'>
          <li>DevOps Consulting Services</li>
          <li>Continuous Integration and Delivery</li>
          <li>Hire DevOps Engineers</li>
          <li>DevOps Tools and Technologies</li>
          <li>Offshore DevOps Team</li>
        </div>
      </div>
      <div>
        <div className='head-items'>
          <img src='imgs' alt='logos-DevOps' />
          <h2> DevOps </h2>
        </div>
        <div className='Service-list'>
          <li>DevOps Consulting Services</li>
          <li>Continuous Integration and Delivery</li>
          <li>Hire DevOps Engineers</li>
          <li>DevOps Tools and Technologies</li>
          <li>Offshore DevOps Team</li>
        </div>
      </div>
      <div>
        <div className='head-items'>
          <img src='imgs' alt='logos-DevOps' />
          <h2> DevOps </h2>
        </div>
        <div className='Service-list'>
          <li>DevOps Consulting Services</li>
          <li>Continuous Integration and Delivery</li>
          <li>Hire DevOps Engineers</li>
          <li>DevOps Tools and Technologies</li>
          <li>Offshore DevOps Team</li>
        </div>
      </div>
      <div>
        <div className='head-items'>
          <img src='imgs' alt='logos-DevOps' />
          <h2> DevOps </h2>
        </div>
        <div className='Service-list'>
          <li>DevOps Consulting Services</li>
          <li>Continuous Integration and Delivery</li>
          <li>Hire DevOps Engineers</li>
          <li>DevOps Tools and Technologies</li>
          <li>Offshore DevOps Team</li>
        </div>
      </div>
    </div>

*/
// }
