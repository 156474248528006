/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./BlogPage.css";
import UXui from "../assets/img/blog/uxui.3d1440103d5db3e66637.jpg";
import { Link } from "react-router-dom";

const BlogPage = () => {
  return (
    <div className='blog-page'>
      {/*Left Section*/}
      <div className='left-section'>
        <ul className='page-list'>
          {Array.from({ length: 5 }, (_, i) => (
            <li key={i}>Page {i + 1}</li>
          ))}
        </ul>
      </div>
      {/*MAin Section*/}
      <div className='middle-section'>
        <img className='img' src={UXui} alt='picato' />
        <h1>UX/UI</h1>
        <p className='topic'>
          The foundation for developing a user-friendly and accessible product
          in online and app development is UI and UX design. UI and UX design
          are necessary for all electronic devices, including computers,
          household appliances, and other mobile and web-based applications. You
          may already be familiar with the phrases UX and UI if you work in
          technology or the digital sector. You may have even overheard the
          terms casually while strolling through the streets of the world's tech
          hubs. They are frequently combined or used interchangeably, yet as we
          shall see, they are two quite distinct concepts. However, each is
          essential to the other's success. So let us examine the application of
          these two fundamental design tools in the various domains that they
          can enable.
        </p>
        <div className='d-flex'>
          <p className='line '></p>
          <p className='date'>Jan 01 , 2023</p>
        </div>
        <h3>What do UX and UI mean?</h3>
        <p className='data-st'>
          The concepts of UX and UI appear to be some kind of foreign language
          at first glance.  You have to be acclimated to a variety of acronyms
          and abbreviations, just like with many technical words. 
          <li>Another two terms to become familiar with are UX and UI.</li>
          <li>In both phrases, the letter "U" stands for "user." </li>
          <li>
            This is because both domains put the user first and are crucial for
            producing a user-friendly experience. 
          </li>
          Any developer, whether they are making an app or simply a coffee
          maker, will understand the value of designing an accessible interface,
          but you also need to make sure it functions properly.  UX and UI both
          play a role in this.<br></br>There is more to it than that, however.
          Let us dive into each of the separate terms, and find out the
          different applications of each design method.
        </p>
        <h3>User Experience</h3>
        <p>
          UX is an abbreviation for User Experience, and as the name implies, UX
          design encompasses the end user's interaction with the company,
          service, or products.  It is worth noting that this method can be
          applied to almost anything, from street lamps to websites. <br></br>A
          non-digital practice, in theory, focuses on the overall feel of a
          product or service.  UX is frequently used in digital industries
          because it involves the interaction between the user and the service. 
          <br></br>
          UX design is a broad term that encompasses all of the various elements
          involved in this experience. The main consideration is how the user
          feels when using the product and how simple it is to use the product
          or service.<br></br>
          UX design is entirely focused on the experience that the user has with
          the product; it has nothing to do with how the service or product
          appears.  This may refer to how simple an online purchase process is
          or whether a banking app makes it simple for you to manage your money
          whether you are online.  UX just considers the effectiveness of
          interactions, not the visual experience.
        </p>
        <h3>User Interface</h3>
        <p>
          User Interface, or UI, is the abbreviation. <br></br>
          In contrast to UX, UI is solely a digital phrase that refers to how a
          product or service appears feels, is presented and interacts with
          users. <br></br>
          It is the point at which a user interacts with a digital product, such
          as a touchscreen on a phone or a screen on a coffee maker where you
          may select the type of coffee you want. You must translate the
          product's development, research, content, and layout into a
          user-friendly and appealing experience as a UI designer. <br></br>
          Consistency, coherence, and aesthetic appeal are essential for the
          design. <br></br>
          You want to design something so that the user can navigate quickly and
          easily without having to think about it too much.
        </p>
        <h3>What is the difference between UX and UI?</h3>
        <h3>UX</h3>
        <p>
          UX is a human-first approach to product design that includes both
          physical and digital applications. <br></br>
          The focus of UX is the user's entire experience, from the first
          contact to the last.<br></br>A UX designer focuses on structural
          design solutions for any issues that the user encounters during their
          interaction to create a product that the user will enjoy because it is
          effective. <br></br>
          UX is frequently the first step in the product development process,
          laying the groundwork for a project, which UI then fills with
          interactive and visual elements.
        </p>
        <h3>UI</h3>
        <p>
          UI represents the opposite side of the metaphorical coin. <br></br>
          Instead of the digital and physical applications of UX, UI is the
          human-first approach to designing the real visual experience of a
          product. Visual touchpoints are what UI design is all about. To
          produce a product that is aesthetically acceptable for users,
          interaction with the digital product comes first on a UI designer's
          list of duties. <br></br>
          The trip planned by UX is made possible by UI, which ranges from
          hitting a button and scrolling down a page to swiping across an image
          gallery.<br></br>
          Despite their professional relationship, the elements themselves are
          quite different. They both relate to very different aspects of the
          development process and the design discipline. They also work closely
          together, both being crucial to the creation and success of a product.
        </p>
        <h3>How do UX and UI work together?</h3>
        <p>
          Now that we have discussed the distinctions between these two crucial
          digital abilities, how do they function together? As we previously
          said, you will frequently see job postings for the combination of
          these roles. This is so that businesses can more frequently mix the
          two into a well-rounded solution. If you're asking which one is more
          significant, the truth is that they are both equally significant.  
          <br></br>
          Everyone wants something that looks fantastic and is incredibly
          usable; from developers to users. This is a manifest for UI and UX.
          While using one without the other has its uses, using both together
          yields the best outcomes. <br></br>
          The advantages of UI and UX for the company are so numerous, and given
          the level of competition in the global market, doing them correctly is
          crucial.  <br></br>
          UI is the cherry on top of the UX cake, to use another analogy.  
          <br></br>
          The entire product may end up being ruined if just one component of it
          does not turn out as expected.
        </p>
        <h3>What sort of skills do you need for UX and UI?</h3>
        <p>
          To succeed in UX and UI, you will need a combination of hard and soft
          skills, just like in any other aspect of the digital industry. 
          <br></br>
          Roles in both UX and UI require exceptional communication and
          interpersonal skills, as you will be interacting with members of the
          other team depending on which design path you take. <br></br>
          Furthermore, anticipating abrupt changes in the digital landscape is
          an important part of the job, and adaptability and emotional
          intelligence are valuable skills to possess. <br></br>
          Many transferable skills can be used in both UX and UI, such as
          graphic design, customer service, and project management.<br></br>.
          Many hard skills are beneficial to know of. <br></br>
          Understanding the various programming languages will set you up well
          to<br></br> begin your journey in UX and UI.  Similarly, knowing the
          fundamentals of HTML and CSS, as well as having some coding knowledge,
          will set you apart from the crowd.<br></br>
          You will quickly realize that the applications of UX and UI touch many
          various domains and businesses, some of which you may find rather
          surprising, ranging from user interface design for the Internet of
          Things to applications of UX and UI in digital marketing for
          hoteliers.<br></br>
          Visit our introduction to UX free course for a fantastic crash course
          in UX design.<br></br>  You will gain complete knowledge of the
          significance of accessible interfaces over a few weeks. If you want
          something a little more in-depth, our Expert Track in UX design
          strategy and application will aid in expanding your knowledge and
          honing your UX abilities.
        </p>
        <h3>How do you learn UX and UI design?</h3>
        <p>
          In the world of technology, UX and UI design are still relatively new
          fields, particularly when it comes to digital applications. 
          <br></br>
          Despite their significance in the corporate world, they are still not
          that generally understood outside of the fields of technology and
          design.<br></br>
          As a result, it can be challenging to find a course in UX and UI at
          the college level.<br></br>  However, we provide several different
          Expert Tracks and Free Courses that cover a variety of UX and UI
          applications. <br></br>
          Additionally, because they are all available online, you may learn
          wherever you are and anytime you choose.<br></br>
        </p>
        <h3>
          What kind of employment opportunites are available in Ux and UI?
        </h3>
        <p>
          Unsurprisingly, the main roles available in the world of UX and UI are
          UX designer and UI designer. <br></br>
          However, many other roles fall under the umbrella of UX and UI design,
          which may be better suited to your skill set and experience. <br></br>
          Let us look.
        </p>
        <h3>User experience researcher</h3>
        <p>
          In this case, the job title gives it away somewhat. <br></br>
          UX researchers concentrate on the research stage of the design process
          in exactly that way.<br></br>  With the use of quantitative and
          qualitative research, you will be tasked with learning more about your
          users and incorporating their wants and motivations into the design of
          your product or service.
        </p>
        <h3>Visual designer</h3>
        <p>
          Many tasks that visual designers perform are also necessary for UI
          success, although a visual designer frequently only concentrates on
          the digital components of design. <br></br>
          They will have familiarity with many web design styles and frequently
          work off a framework created by a UX designer, which UI designers do
          not always do.<br></br>
        </p>
        <h3>UX copywriter</h3>
        <p>
          The world of UX is not immune to copy writing’s grips because it is
          pervasive in today's society. <br></br>A UX copywriter must provide
          all of the text that a user can encounter when engaging with the
          product. <br></br>
          Copy that is helpful and succinct is the name of the game for UX
          copywriters, who differ from marketing copywriters in that they are
          expected to make the experience as seamless as possible.
        </p>
        <h3>GUI designer</h3>
        <p>
          The user can interact with software using graphics rather than words
          using a graphical user interface, or GUI. <br></br>A GUI is, for
          instance, Microsoft Windows.<br></br>  GUI designers, who frequently
          program this program in Python, make these interfaces.
        </p>
        <h3>Final thoughts</h3>
        <p>
          The modern world depends heavily on UX and UI, thus many companies
          will be searching for applicants that have some background in one or
          both of these design fields. <br></br>  Technology takes center stage
          as we experience the Fourth Industrial Revolution, and businesses will
          strive to provide people with clean and seamless digital experiences.
          <br></br>  They both require quite distinct skill sets, yet they
          complement one another well because each is essential to the success
          of the other. <br></br>  Just as a perfectly acceptable user
          experience can be ruined by poor visual interface design, a
          wonderfully designed website cannot compensate for a confused
          interface. <br></br>  However, when everything comes together, the
          effects may be stunning.
        </p>
        <Link to='/blog' className='text-center'>
          <button linkto='./Blogs.jsx' className='btn-learn-more'>
            <i className='fa fa-arrow-left' />
            Go Back
          </button>
        </Link>
      </div>
      {/* Right Section */}
      <div className=' right-section'>
        <div className='search-bar'>
          <input type='text' placeholder='Search' />
          <button className='bnt'>Search</button>
        </div>
        <ul className='blog-list'>
          <li>
            <a href='/devops'> The Ultimate Guide To Devops </a>
          </li>
          <li>
            <a href='/DevopsCons'>Devops Consultancy</a>
          </li>
          <li>
            <a href='#'>Deployment</a>
          </li>
          <li>
            <a href='/Uxui'>The Definitive Guide to UXUI</a>
          </li>
          <li>
            <a href='#'>
              The Definitive to <br></br> React and React Native{" "}
            </a>
          </li>
          <li>
            <a href='#'>
              The Definitive To <br></br>Cloud-based Services
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlogPage;
